import React, { useState, useEffect, useRef, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./AreaFacilitador.css";
import Slide from "./Slide";
import Topo from "./Topo";
import BlocoSequenciaTema from "../BlocoSequenciaTema/BlocoSequenciaTema";
import BlocoExercicios from "./BlocoExercicios";
import "bootstrap/dist/css/bootstrap.min.css";
import { autoFocoExercicio, removeElementFromArray2D } from "./functions";
import ModalDesfazer from "../Modal/ModalDesfazer/ModalDesfazer";
import ModalAviso from "../Modal/ModalAviso";
import ModalCompilacao from "../Modal/ModalCompilacao";
import DialogText from "../DialogText";
import { getAuthData } from "../../auth";
import { jwtDecode } from "jwt-decode";
import { Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import "../SegundoTopo/SegundoTopo";
import SegundoTopo from "../SegundoTopo/SegundoTopo";
import ModalGeracaoLadoALadoB from "../Modal/ModalGeracaoLadoALadoB";
import ModalPuxaMaterialPadrao from "../Modal/ModalPuxaMaterialPadrao";

export const handleModuloSelect = (modulo) =>{
  return 
}

const divStyle = {
  height: "100%",
  margin: "1%",
};

const presentationTemplate = "https://docs.google.com/presentation/d/";
const apiUrl = process.env.REACT_APP_API_HOST;
// const apiUrl = "http://127.0.0.1:5000";

function AreaFacilitador() {
  const location = useLocation();
  const navigate = useNavigate();
  /** Armazena nome do módulo selecionado pelo facilitador */
  const [selectedModulo, setSelectedModulo] = useState(null);
  /** Armazena todos os módulos relacionados ao facilitador logado. */
  const [modulos, setModulos] = useState([]);
  /** Variável principal da area dos facilitadores pois guarda os metadados do temas de estudo
      codigo do tema, 
      texto da descobertaguiada relacionado ao tema,
      texto do handson relacionado ao tema,
      nome do tema de estudo,
      numero_aula do tema,
      sequencia_tema,
      isSelected: flag que indica se o tema está selecionado pelo usuário,
      link_apresentacao: link da apresentação do tema,
   */
  const [temasestudo, setTemasEstudo] = useState([]);
  /** Lista 2D com os ids de versao de cada tema de estudo. Cada lista 1D interna representa uma aula. */
  const [sequencia_versoes, setSequenciaVersoes] = useState([]);
  const [sequenciaVersoesAnterior, setSequenciaVersoesAnterior] = useState([]);

  const [temasestudoRemovido, setTemasEstudoRemovido] = useState([]);
  const [infouser, setInfouser] = useState({
    email: "",
    userlevel: "usuario",
    link_ladoA_ladoB: "",
  });
  const [displayTextDescricao, setDisplayTextDescricao] = useState("");
  const [displayComecepeloporque, setDisplayComecepeloporque] = useState("");
  const [open, setOpen] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [firstModuloSelected, setFirstModuloSelected] = useState(false);
  const [slide, setSlide] = useState(
    presentationTemplate +
      "1BH7slyC2NoTfjENvWsEspJNbsYF_UVMSDqmnBbwAedg/edit#slide=id.g19768af6b82_2_0?rm=minimal"
  );
  const [linkCompilado, setLinkCompilado] = useState({
    link: "",
    data_criacao: "",
    personalizado: false,
    link_padrao: "",
  });
  /** Flag que indica se uma apresentação é personalizada ou padrão. */
  const [apresentacaoPersonalizada, setApresentacaoPersonalizada] =
    useState(false);
  const [temaadicionado, settemaadicionado] = useState(false);

  /** Variável que armazena o nome do novo tema de estudo adicionado. */
  const [valorInput, setValorInput] = useState("");
  /** Index do tema selecionado de acordo com a ordem apresentada na sequencia tema estudo do front. */
  const [selectedPraticaId, setSelectedPraticaId] = useState(0);
  /** Variáveis para armazenar os textos a serem substituídos dos exercícios de descobertaguiada e handson */
  const [textoUsuarioHandson, setTextoUsuarioHandson] = useState(null);
  const [textoUsuarioDescobertaguiada, setTextoUsuarioDescobertaguiada] =
    useState(null);
  /** Usado para controlar a aparição do modal de desfazer ação (remover tema) */
  const [mostrarModal, setMostrarModal] = useState(false);
  const [mostrarModalRemoveAula, setMostrarModalRemoveAula] = useState(false);
  const [mostrarModalSalvar, setMostrarModalSalvar] = useState(false);
  const [mostrarModalCompilacao, setMostrarModalCompilacao] = useState(false);
  const [mostrarModalladoAladoB, setMostrarModalladoAladoB] = useState(false);
  const [mostrarModalMaterialPadrao, setMostrarModalMaterialPadrao] =
    useState(false);
  const [textoModalSalvar, setTextoModalSalvar] = useState("");
  const [textoModalCompilacao, setTextoModalCompilacao] = useState("");

  const [infoNovoModulo, setInfoNovoModulo] = useState({});

  const [facilitadores, setFacilitadores] = useState([]);
  const [selectedFacilitador, setSelectedFacilitador] = useState(null);

  // Ref para a instância de BlocoSequenciaPraticas
  const blocoSequenciaPraticasRef_descobertaguiada = useRef(null);
  const blocoSequenciaPraticasRef_handson = useRef(null);

  /*********************************************************************************************************
   * Funções para adaptar a caixa de texto de edição das descobertas guiadas e handson ao tamanho do texto
   **********************************************************************************************************/
  const handleTextoUsuarioChangeDescobertaguiada = (event) => {
    // const textarea = event.target;
    // // textarea.style.height = 'auto'; // Redefine a altura para auto para calcular a altura necessária.
    // const alturaPixels = textarea.scrollHeight;
    // const alturaPai = textarea.parentElement.scrollHeight;
    // const alturaEmPorcentagem = (alturaPixels / alturaPai) * 100;
    // textarea.style.height = `${Math.min(alturaEmPorcentagem, 50)}%`; // Define a altura com um máximo de 50%.
    setTextoUsuarioDescobertaguiada(event.target.value);
  };

  const handleTextoUsuarioChangeHandson = (event) => {
    // const textarea = event.target;
    // // textarea.style.height = 'auto'; // Redefine a altura para auto para calcular a altura necessária.
    // const alturaPixels = textarea.scrollHeight;
    // const alturaPai = textarea.parentElement.scrollHeight;
    // const alturaEmPorcentagem = (alturaPixels / alturaPai) * 100;
    // textarea.style.height = `${Math.min(alturaEmPorcentagem, 50)}%`; // Define a altura com um máximo de 50%.
    setTextoUsuarioHandson(event.target.value);
  };

  /*********************************************************************************************************/

  /****************************************************************************************
   * Funções para confirmação de textos de descobertaguiada e handson
   * As funções do tipo KeyDown permitem que o usuário confirme a mudança ao clicar 'Enter'
   *****************************************************************************************/
  const handleKeyDownDescobertaguiada = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      // Se a tecla Enter for pressionada, chame a lógica do botão Enviar
      handleConfirmClickDescobertaguiada();
    }
  };

  const handleConfirmClickDescobertaguiada = () => {
    // Criar uma nova cópia do array com a modificação
    const novoArray = temasestudo.map((objeto) => {
      if (temasestudo.indexOf(objeto) === selectedPraticaId) {
        // Modificar a chave específica
        return { ...objeto, descobertaguiada: textoUsuarioDescobertaguiada };
      }
      return objeto;
    });
    setTemasEstudo(novoArray);
  };

  const handleRemoveClickDescobertaguiada = () => {
    // Criar uma nova cópia do array com a modificação
    const novoArray = temasestudo.map((objeto) => {
      if (temasestudo.indexOf(objeto) === selectedPraticaId) {
        // Modificar a chave específica
        return { ...objeto, descobertaguiada: "" };
      }
      return objeto;
    });
    setTextoUsuarioDescobertaguiada("");
    setTemasEstudo(novoArray);
  };

  const handleKeyDownHandsOn = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      // Se a tecla Enter for pressionada, chame a lógica do botão Enviar
      handleConfirmClickHandson();
    }
  };

  const handleConfirmClickHandson = () => {
    // Criar uma nova cópia do array com a modificação
    const novoArray = temasestudo.map((objeto) => {
      if (temasestudo.indexOf(objeto) === selectedPraticaId) {
        // Modificar a chave específica
        return { ...objeto, handson: textoUsuarioHandson };
      }
      return objeto;
    });
    setTemasEstudo(novoArray);
  };

  const handleRemoveClickHandson = () => {
    // Criar uma nova cópia do array com a modificação
    const novoArray = temasestudo.map((objeto) => {
      if (temasestudo.indexOf(objeto) === selectedPraticaId) {
        // Modificar a chave específica
        return { ...objeto, handson: "" };
      }
      return objeto;
    });
    setTextoUsuarioHandson("");
    setTemasEstudo(novoArray);
  };
  /******************************************************************************** */

  /********************************************************************************
   * Essa função é enviada como callback para o componente SequenciaTemasEstudo.
   * Dentro do componente, quando existe mudança na ordem dos temas, a função é chamada.
   * A função modificarLista garante que a nova ordem dos temas é salva na variável de estado temasestudo.
   ***********************************************************************************/
  const modificarLista = (novaLista) => {
    // Pegando apenas as informações necessárias para alimentar a temasestudo
    const arrayDeObjetos = novaLista.map((item, index) => ({
      codigo: item.codigo,
      descobertaguiada: item.descobertaguiada,
      handson: item.handson,
      nome: item.nome,
      numero_aula: item.numero_aula,
      sequencia_tema: item.sequencia_tema,
      isSelected: item.isSelected,
      link_apresentacao: item.link_apresentacao,
    }));
    setTemasEstudo(arrayDeObjetos);
  };

  /******************************************************************************** */

  /********************************************************************************
   * Essa função é enviada como callback para o componente SequenciaTemasEstudo.
   * Dentro do componente, quando existe mudança na ordem dos temas, a função é chamada.
   * A função ModificarListaVersoes garante que a nova ordem das versões é salva na variável de estado sequenciaversoes.
   * Além disso, seta o novo bloco selecionado.
   ***********************************************************************************/
  const ModificarListaVersoes = (novaLista, selectedBlocoid) => {
    setSequenciaVersoes(novaLista);
    setSelectedPraticaId(selectedBlocoid);
  };

  /******************************************************************************** */

  /*********************************************************************************
   * useEffect carrega os módulos relacionados ao facilitador usuário da ferramenta.
   * O carregamento é feito sempre que as variáveis user ou location forem modificadas
   ***********************************************************************************/
  useEffect(() => {
    setLoading(true);
    setOpenLoading(true);
    const authData = getAuthData();
    var email = "";
    var level = "";
    if (authData) {
      if (selectedFacilitador){
        email = selectedFacilitador;
      }
      else{
        email = jwtDecode(authData.token).sub.user;
      }
      level = jwtDecode(authData.token).sub.level;
      console.log(email,"\n",level)
      if (email === "dan") {
        email = "yamadadan@hotmail.com";
      }
      setInfouser({
        ...infouser,
        email: email,
        userlevel: level,
      });

      fetch(`${apiUrl}/api/mentormodulo?email=${email}&level=${level}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setModulos(data);
          fetch(`${apiUrl}/api/ladoAladoB?email=${email}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then((response) => response.json())
            .then((data) => {
              setInfouser({
                ...infouser,
                email: email,
                userlevel: level,
                link_ladoA_ladoB: data,
              });
              setLoading(false);
              setOpenLoading(false);
            })
            .catch((error) => {
              console.error("Erro na solicitação:", error);
              alert("Erro na solicitação:", error);
              setInfouser({
                ...infouser,
                email: email,
                userlevel: level,
              });
              setLoading(false);
              setOpenLoading(false);
            });
        })
        .catch((error) => {
          console.error("Erro na solicitação:", error);
          setLoading(false);
          setOpenLoading(false);
        });
    } else {
      setLoading(false);
      setOpenLoading(false);
    }
  }, [selectedFacilitador]);
  /******************************************************************************** */
  // A passagem de um array vazio ([]) como o segundo argumento do useEffect garante que a função seja executada somente uma vez após a renderização inicial

  /*********************************************************************************
   * useEffect para sempre que o selectedPraticaId receber um novo valor.
   * Realiza a localização dos exercicios de descobertaguiada e handson de acordo com o tema de estudo selecioando.
   ***********************************************************************************/
  useEffect(() => {
    autoFocoExercicio(
      blocoSequenciaPraticasRef_descobertaguiada,
      blocoSequenciaPraticasRef_handson,
      selectedPraticaId
    );
  }, [selectedPraticaId]);

  /******************************************************************************** */

  /*********************************************************************************
   * useEffect para mostrar o modal de refazer ação.
   ***********************************************************************************/
  useEffect(() => {
    if (mostrarModal) {
      const timer = setTimeout(() => {
        setMostrarModal(false);
      }, 5000); // 5 segundos

      return () => clearTimeout(timer);
    }
    if (mostrarModalRemoveAula) {
      const timer = setTimeout(() => {
        setMostrarModalRemoveAula(false);
      }, 5000); // 5 segundos

      return () => clearTimeout(timer);
    }

    if (mostrarModalCompilacao) {
      const timer = setTimeout(() => {
        setMostrarModalCompilacao(false);
        logOut();
      }, 5000); // 5 segundos

      return () => clearTimeout(timer);
    }
  }, [mostrarModal, mostrarModalCompilacao]);

  const onCloseModalCallback = () => {
    setMostrarModal(false);
  };

  const onCloseModalCallbackRemoveAula = () => {
    setMostrarModalRemoveAula(false);
  };

  /******************************************************************************** */

  /*********************************************************************************
   * Função para carregar os temas de estudo relacionados ao módulo selecionado pelo facilitador usuário da ferramenta.
   * Após carregar os temas, seleciona o primeiro tema de estudo por padrão
   * Realiza a busca de link de aprosentação previamente compilada do facilitador
   * Seta sequencia de versões dos temas relacionadas ao facilitador (informada pelo backend)
   ***********************************************************************************/
  const handleModuloSelect = (modulo) => {
    if (!modulo) {
      return;
    }
    setSelectedModulo(modulo);
    setLoading(true);
    setOpen(true);
    const email = selectedFacilitador ? selectedFacilitador : infouser.email;
    fetch(
      `${apiUrl}/api/selectmodulo?modulo=${modulo}&email=${email}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setDisplayTextDescricao(data["objetivo_modulo"]);
        setDisplayComecepeloporque(data["link_comecepeloporque"]);
        settemaadicionado(false);

        if (!("erro" in data["info_temasestudo"])) {
          const arrayDeCodigos = data["info_temasestudo"].map(
            (objeto) => objeto.codigo
          );
          setTemasEstudo(data["info_temasestudo"]);
          if (data["info_temasestudo"].length > 0) {
            // Primeiro tema de estudo é selecionado por padrão
            selectTemaEstudo(
              data["info_temasestudo"],
              arrayDeCodigos[0],
              modulo,
              0
            );
          }
        } else {
          setTemasEstudo([]);
        }

        if ("link" in data["info_apresentacao_compilada"]) {
          const dataOriginal = new Date(
            data["info_apresentacao_compilada"]["data_criacao"]
          );
          const opcoesFormato = {
            day: "numeric",
            month: "numeric",
            year: "numeric",
          };
          const formatoData = new Intl.DateTimeFormat("pt-BR", opcoesFormato);
          const dataFormatada = formatoData.format(dataOriginal);
          setLinkCompilado({
            link: data["info_apresentacao_compilada"]["link"],
            data_criacao: dataFormatada,
            personalizado: data["info_apresentacao_compilada"]["personalizado"],
            link_padrao: data["info_apresentacao_compilada"]["link_padrao"],
          });
        } else {
          setLinkCompilado({
            link: "Não possui material compilado prévio",
            data_criacao: "",
            personalizado: "",
            link_padrao: data["info_apresentacao_compilada"]["link_padrao"],
          });
        }
        setSequenciaVersoes(data["sequencia_versoes"]);
        setLoading(false);
        setOpen(false);
        if (!selectedModulo) {
          setFirstModuloSelected(true);
        }
      })
      .catch((error) => {
        console.error("Erro na solicitação:", error);
        setTemasEstudo([]);
        setSequenciaVersoes([]);
        setLoading(false);
        setOpen(false);
        if (!selectedModulo) {
          setFirstModuloSelected(true);
        }
      });

    setTemasEstudoRemovido([]); // Reinicia array de temas de estudo removidos
  };

  const handleGetPadrao = (modulo) => {
    setLoading(true);
    setOpen(true);
    fetch(
      `${apiUrl}/api/getmaterialpadrao?modulo=${modulo}&email=${infouser.email}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (!("erro" in data["info_temasestudo"])) {
          const arrayDeCodigos = data["info_temasestudo"].map(
            (objeto) => objeto.codigo
          );
          setTemasEstudo(data["info_temasestudo"]);
          if (data["info_temasestudo"].length > 0) {
            // Primeiro tema de estudo é selecionado por padrão
            selectTemaEstudo(
              data["info_temasestudo"],
              arrayDeCodigos[0],
              modulo,
              0
            );
          }
        } else {
          setTemasEstudo([]);
        }

        if ("link" in data["info_apresentacao_compilada"]) {
          const dataOriginal = new Date(
            data["info_apresentacao_compilada"]["data_criacao"]
          );
          const opcoesFormato = {
            day: "numeric",
            month: "numeric",
            year: "numeric",
          };
          const formatoData = new Intl.DateTimeFormat("pt-BR", opcoesFormato);
          const dataFormatada = formatoData.format(dataOriginal);
          setLinkCompilado({
            link: data["info_apresentacao_compilada"]["link"],
            data_criacao: dataFormatada,
            personalizado: data["info_apresentacao_compilada"]["personalizado"],
            link_padrao: data["info_apresentacao_compilada"]["link_padrao"],
          });
        } else {
          setLinkCompilado({
            link: "Não possui material compilado prévio",
            data_criacao: "",
            personalizado: "",
            link_padrao: data["info_apresentacao_compilada"]["link_padrao"],
          });
        }
        setSequenciaVersoes(data["sequencia_versoes"]);
      })
      .catch((error) => {
        console.error("Erro na solicitação:", error);
        setTemasEstudo([]);
        setSequenciaVersoes([]);
      })
      .finally(() => {
        setLoading(false);
        setOpen(false);
      });

    setTemasEstudoRemovido([]); // Reinicia array de temas de estudo removidos
  };

  /******************************************************************************** */

  /*********************************************************************************
   * Função que realiza o pedido de compilação do material
   ***********************************************************************************/
  const handleButtonCompilar = () => {
    setLoading(true);
    setOpen(true);
    if (possuiArrayInternoVazio(sequencia_versoes)) {
      console.error(
        "Erro na solicitação:",
        "Compilação com aulas vazias não permitido."
      );
      setLoading(false);
      setOpen(false);
      alert("Compilação com aulas vazias não permitido.");
      return "Compilação com aulas vazias não permitido.";
    }
    var moduloselecionado = "";
    if (selectedModulo && selectedModulo.includes("Novo! - ")) {
      moduloselecionado = selectedModulo.replace("Novo! - ", "");
    } else {
      moduloselecionado = selectedModulo;
    }
    fetch(`${apiUrl}/api/compilarmaterial`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        temasestudo: temasestudo,
        sequencia_versoes: sequencia_versoes,
        modulo: moduloselecionado,
        email: infouser.email,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setSequenciaVersoes(data["seq_versoes_aula"]);
        setLoading(false);
        setOpen(false);
        setTextoModalCompilacao(
          "Compilação do material requisitada com sucesso. Por gentileza, aguarde o recebimento do material via e-mail."
        );
        setMostrarModalCompilacao(true);
      })
      .catch((error) => {
        console.error("Erro na solicitação:", error);
        setLoading(false);
        setOpen(false);
        setTextoModalCompilacao(
          "Houve erro na solicitação de compilação do material. Favor contactar o atendimento."
        );
        setMostrarModalCompilacao(true);
      });
  };

  function possuiArrayInternoVazio(arr2D) {
    for (let i = 0; i < arr2D.length; i++) {
      if (Array.isArray(arr2D[i]) && arr2D[i].length === 0) {
        return true; // Retorna true se encontrar um array interno vazio
      }
    }
    return false; // Retorna false se nenhum array interno vazio for encontrado
  }
  /*********************************************************************************
   * Função que realiza o pedido de salvamento do material
   ***********************************************************************************/
  const handleButtonSalvar = () => {
    setLoading(true);
    setOpen(true);
    if (possuiArrayInternoVazio(sequencia_versoes)) {
      console.error(
        "Erro na solicitação:",
        "Salvamento com aulas vazias não permitido."
      );
      setLoading(false);
      setOpen(false);
      alert("Salvamento com aulas vazias não permitido.");
      return "Salvamento com aulas vazias não permitido.";
    }
    var moduloselecionado = "";
    if (selectedModulo && selectedModulo.includes("Novo! - ")) {
      moduloselecionado = selectedModulo.replace("Novo! - ", "");
    } else {
      moduloselecionado = selectedModulo;
    }
    const startTime = Date.now(); // Registra o tempo no início do fetch
    fetch(`${apiUrl}/api/salvarmaterial`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        temasestudo: temasestudo,
        sequencia_versoes: sequencia_versoes,
        modulo: moduloselecionado,
        email: infouser.email,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          // Lança um erro para ser capturado no catch com status
          return response.json().then((err) => {
            throw new Error(response.status);
          });
        }
        return response.json();
      })
      .then((data) => {
        setSequenciaVersoes(data.seq_versoes_aula);
        setLoading(false);
        setOpen(false);
        setTextoModalSalvar("Salvamento do material concluído");
        setMostrarModalSalvar(true);
      })
      .catch((error) => {
        const endTime = Date.now(); // Registra o tempo quando o catch é acionado
        const timeElapsed = (endTime - startTime) / 1000; // Calcula o tempo em segundos
        console.error("Erro na solicitação:", error);
        setLoading(false);
        setOpen(false);
        if (timeElapsed > 30) {
          // Lógica de erro 503
          setTextoModalSalvar(
            "O salvamento está demorando um pouco mais que o esperado. Por gentileza, acesse seu material novamente mais tarde para verificar se foi salvo corretamente.\nPara maiores informações, por gentileza contate o atendimento."
          );
          setMostrarModalSalvar(true);
        } else {
          // Outros erros
          setTextoModalSalvar(
            "Houve erro no inesperado salvamento do material. Por favor, tente novamente mais tarde ou contate o atendimento."
          );
          setMostrarModalSalvar(true);
        }
      });
  };

  const addTemaEstudo = () => {
    setLoading(true);
    setOpen(true);
    var moduloselecionado = "";
    if (selectedModulo && selectedModulo.includes("Novo! - ")) {
      moduloselecionado = selectedModulo.replace("Novo! - ", "");
    } else {
      moduloselecionado = selectedModulo;
    }
    fetch(`${apiUrl}/api/addtemaestudo`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        modulo: moduloselecionado,
        email: infouser.email,
        novotema: valorInput,
        novasigla:
          "siglamodulo" in infoNovoModulo ? infoNovoModulo["siglamodulo"] : "",
        sequencia_versoes: sequencia_versoes,
        temasestudo: temasestudo,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setSlide(presentationTemplate + data.id_link_temaestudo.toString());
        setApresentacaoPersonalizada({
          nome_tema: data.temasestudo[data.temasestudo.length - 1]["nome"],
          data_criacao:
            data.temasestudo[data.temasestudo.length - 1]["link_apresentacao"][
              "data_criacao"
            ],
          flag_material_padrao:
            data.temasestudo[data.temasestudo.length - 1]["link_apresentacao"][
              "flag_material_padrao"
            ],
        });
        setSequenciaVersoes(data.sequencia_versoes);
        setTemasEstudo(data.temasestudo);
        setSelectedPraticaId(data.temasestudo.length - 1);
        setTextoUsuarioDescobertaguiada("");
        setTextoUsuarioHandson("");
        setLoading(false);
        setOpen(false);
      })
      .catch((error) => {
        console.error("Erro na solicitação:", error);
        alert("Erro na solicitação:", error);
        setLoading(false);
        setOpen(false);
      });
  };

  const removeTemaEstudo = () => {
    /** Mostra modal de refazer ação */
    setMostrarModal(true);
    // Adicionando tema de estudo removido ao array
    setSequenciaVersoesAnterior(sequencia_versoes);
    // Adicionando tema de estudo removido ao array
    setTemasEstudoRemovido((prevTemasEstudo) => [
      ...prevTemasEstudo,
      { tema: temasestudo[selectedPraticaId], index: selectedPraticaId },
    ]);

    // Removendo tema de estudo do array de temasestudo
    // 1. Crie uma cópia do array original
    const newArray = [...temasestudo];
    const newArrayVersoes = [...sequencia_versoes];
    // 2. Encontre o índice do item a ser removido
    const indexToRemove = newArray.findIndex(
      (item) => item.codigo === newArray[selectedPraticaId].codigo
    );
    // 3. Remova o item da cópia do array
    if (indexToRemove !== -1) {
      newArray.splice(indexToRemove, 1);
      // Verifique se existe um próximo item
      if (indexToRemove < newArray.length) {
        // Defina a chave 'isSelected' do próximo item como true
        newArray[indexToRemove].isSelected = true;
        selectTemaEstudo(
          newArray,
          newArray[indexToRemove].codigo,
          selectedModulo
        );
      } else {
        // Defina a chave 'isSelected' do item anterior como true
        newArray[indexToRemove - 1].isSelected = true;
        setSelectedPraticaId(selectedPraticaId - 1);
        selectTemaEstudo(
          newArray,
          newArray[indexToRemove - 1].codigo,
          selectedModulo
        );
      }
    }
    const newArrayVersoesAfterRemoval = removeElementFromArray2D(
      newArrayVersoes,
      indexToRemove
    );
    setSequenciaVersoes(newArrayVersoesAfterRemoval);
    setTemasEstudo(newArray);
  };

  const desfazerRemoveTemaEstudo = () => {
    // Removendo tema de estudo do array de temasestudo
    // 1. Crie uma cópia do array original
    const newArray = [...temasestudo];
    // Inserir o item no índice especificado
    newArray.splice(
      temasestudoRemovido[temasestudoRemovido.length - 1]["index"],
      0,
      temasestudoRemovido[temasestudoRemovido.length - 1]["tema"]
    );
    // Seta isSelected apenas para o item readicionado
    const novosBlocos = newArray.map((tema, index) => ({
      ...tema,
      isSelected:
        index === temasestudoRemovido[temasestudoRemovido.length - 1]["index"]
          ? true
          : false,
    }));
    selectTemaEstudo(novosBlocos);
    // Atualizar o estado com o novo array
    setSequenciaVersoes(sequenciaVersoesAnterior);
    setTemasEstudo(novosBlocos);
    setMostrarModal(false);
  };

  const removeAula = () => {
    /** Mostra modal de refazer ação */
    setMostrarModalRemoveAula(true);
    // Adicionando tema de estudo removido ao array
    setSequenciaVersoesAnterior(sequencia_versoes);

    // Verificando se o array 2D tem pelo menos dois arrays internos
    if (sequencia_versoes.length >= 2) {
      // Criando uma cópia do array2D atual
      const novoArray2D = [...sequencia_versoes];
      // Pegando o último array 1D interno
      let ultimoArray1D = novoArray2D.pop();

      // Pegando o penúltimo array 1D interno
      let penultimoArray1D = novoArray2D.pop();

      // Concatenando os dois arrays
      let novoArray1D = penultimoArray1D.concat(ultimoArray1D);

      // Adicionando o novo array ao array 2D
      novoArray2D.push(novoArray1D);

      // Exibindo o array 2D resultante
      setSequenciaVersoes(novoArray2D);
    } else {
      console.error("O array 2D não tem pelo menos dois arrays internos.");
    }
  };

  const desfazerRemoveAula = () => {
    setSequenciaVersoes(sequenciaVersoesAnterior);
    setMostrarModalRemoveAula(false);
  };

  const handleCancel = (openinputfunc) => {
    // setOpenInputTema(false);
    setValorInput("");
    openinputfunc(false);
  };

  const handleSubmit = (addfunc, openinputfunc) => {
    // Limpe o input e feche o dialog
    setValorInput("");
    openinputfunc(false);
    addfunc();
  };

  const handleSubmitAula = () => {
    // Limpe o input e feche o dialog
    setValorInput("");
    addAula();
  };

  const handleKeyDown = (event, addfunc, openinputfunc) => {
    if (event.key === "Enter" && !event.shiftKey) {
      // Se a tecla Enter for pressionada, chame a lógica do botão Enviar
      handleSubmit(addfunc, openinputfunc);
    }
  };

  const handleKeyDownAula = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      // Se a tecla Enter for pressionada, chame a lógica do botão Enviar
      handleSubmitAula();
    }
  };

  const handleRemoveSubmit = () => {
    // Faça algo com o valor inserido, por exemplo, exiba um alerta
    // alert(`Você inseriu: ${valorInput}`);
    removeTemaEstudo();
  };

  const handleKeyDownRemove = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      // Se a tecla Enter for pressionada, chame a lógica do botão Enviar
      handleRemoveSubmit();
    }
  };

  const logOut = () => {
    navigate("/");
  };

  const generateLadoALadoB = () => {
    setLoading(true);
    setOpen(true);
    // CHAMAR ENDPOINT DE GERAÇÃO DE LINK_LADOA_LADOB
    fetch(`${apiUrl}/api/ladoAladoB?email=${infouser.email}&novo=${true}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const newTab = window.open("", "_blank");
        newTab.opener = null; // Impede que a nova aba tenha acesso ao navegador pai
        newTab.location.href = data;
        setInfouser({
          ...infouser,
          link_ladoA_ladoB: data,
        });
      })
      .catch((error) => {
        console.error("Erro na solicitação:", error);
        alert(
          "O facilitador não possui slides de LadoA LadoB cadastrado. Por gentileza, entre em contato com o atendimento."
        );
      })
      .finally(() => {
        setLoading(false);
        setOpen(false);
      });
  };

  const EditTemaEstudo = () => {
    setLoading(true);
    setOpen(true);
    // Transforma o array 2D em 1D
    const seq_versoes1D = sequencia_versoes.flat();

    // Abre a nova aba com a mensagem de "Carregando, por favor, aguarde"
    const newTab = window.open("", "_blank");
    newTab.opener = null; // Impede que a nova aba tenha acesso ao navegador pai

    const estilo = `
      font-size: 24px;
      text-align: center;
    `;

    newTab.document.write(`
      <div style="${estilo}">
        Processando solicitação. Por favor, aguarde<span class="dot" id="dot1">.</span><span class="dot" id="dot2">.</span><span class="dot" id="dot3">.</span>
      </div>
      <style>
        .dot {
          opacity: 0;
          display: inline-block;
        }

        #dot1 {
          animation: dotsFade 4s infinite; /* 3 segundos por ponto, 3 pontos */
        }

        #dot2 {
          animation: dotsFade 4s infinite 1s; /* Inicia 3 segundos após o início */
        }

        #dot3 {
          animation: dotsFade 4s infinite 2s; /* Inicia 6 segundos após o início */
        }

        @keyframes dotsFade {
          0%, 100% {
            opacity: 0;
          }
          25%, 75% {
            opacity: 1;
          }

        }
      </style>
    `);
    var moduloselecionado = "";
    if (selectedModulo && selectedModulo.includes("Novo! - ")) {
      moduloselecionado = selectedModulo.replace("Novo! - ", "");
    } else {
      moduloselecionado = selectedModulo;
    }
    fetch(`${apiUrl}/api/edittemaestudo`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id_versao: seq_versoes1D[selectedPraticaId],
        temaestudo: temasestudo[selectedPraticaId],
        modulo: moduloselecionado,
        email: infouser.email,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setSlide(data["link_apresentacao"]);
        setApresentacaoPersonalizada({
          nome_tema: temasestudo[selectedPraticaId]["nome"],
          data_criacao: data["data_criacao"],
          flag_material_padrao: data["flag_material_padrao"],
        });
        newTab.location.href = data["link_apresentacao"];
        // Modificando apenas um item do array temasestudo
        // Criar uma nova cópia do array com a modificação
        const novoArray = temasestudo.map((objeto, index) => {
          if (index === selectedPraticaId) {
            // Modificar a chave específica
            return {
              ...objeto,
              link_apresentacao: {
                data_criacao: objeto.link_apresentacao.data_criacao,
                flag_material_padrao:
                  objeto.link_apresentacao.flag_material_padrao,
                link_apresentacao: data["link_apresentacao"],
              },
            };
          }
          return objeto;
        });
        // Atualiza a variável temasestudo
        setTemasEstudo(novoArray);

        setLoading(false);
        setOpen(false);
      })
      .catch((error) => {
        console.error("Erro na solicitação:", error);
        alert("Erro na solicitação:", error);
        setLoading(false);
        setOpen(false);
      });
  };

  const selectTemaEstudo = (
    temaestudoArray,
    codigo = 0,
    modulo = "valorPadrao"
  ) => {
    setLoading(true);
    setOpen(true);
    var temaestudoselecionado = temaestudoArray.find(
      (objeto) => objeto.isSelected
    );
    var indicetemaselecionado = 0;
    var moduloselecionado = "";
    if (selectedModulo && selectedModulo.includes("Novo! - ")) {
      moduloselecionado = selectedModulo.replace("Novo! - ", "");
    } else {
      moduloselecionado = selectedModulo;
    }
    if (moduloselecionado) {
      // Na seleção automatica que acontece quando o modulo é selecionado, o selectedModulo é nulo
      if (temaestudoselecionado) {
        indicetemaselecionado = temaestudoArray.indexOf(temaestudoselecionado);
      }
    } else {
      console.error(temaestudoArray);
    }

    setSlide(temaestudoselecionado["link_apresentacao"]["link_apresentacao"]);
    setSelectedPraticaId(indicetemaselecionado);
    const novosBlocos = temaestudoArray.map((tema, index) => ({
      ...tema,
      isSelected: index === indicetemaselecionado ? true : false,
    }));
    setTemasEstudo(novosBlocos);
    setApresentacaoPersonalizada({
      nome_tema: temaestudoselecionado["nome"],
      data_criacao: temaestudoselecionado["link_apresentacao"]["data_criacao"],
      flag_material_padrao:
        temaestudoselecionado["link_apresentacao"]["flag_material_padrao"],
    });
    setTextoUsuarioDescobertaguiada(temaestudoselecionado["descobertaguiada"]);
    setTextoUsuarioHandson(temaestudoselecionado["handson"]);
    setLoading(false);
    setOpen(false);
  };

  const addAula = () => {
    setLoading(true);
    setOpen(true);
    var moduloselecionado = "";
    if (selectedModulo && selectedModulo.includes("Novo! - ")) {
      moduloselecionado = selectedModulo.replace("Novo! - ", "");
    } else {
      moduloselecionado = selectedModulo;
    }
    fetch(`${apiUrl}/api/addtemaestudo`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        modulo: moduloselecionado,
        email: infouser.email,
        novotema: valorInput,
        novasigla:
          "siglamodulo" in infoNovoModulo ? infoNovoModulo["siglamodulo"] : "",
        sequencia_versoes: [...sequencia_versoes, []],
        temasestudo: temasestudo,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setSlide(presentationTemplate + data.id_link_temaestudo.toString());
        setApresentacaoPersonalizada({
          nome_tema: data.temasestudo[data.temasestudo.length - 1]["nome"],
          data_criacao:
            data.temasestudo[data.temasestudo.length - 1]["link_apresentacao"][
              "data_criacao"
            ],
          flag_material_padrao:
            data.temasestudo[data.temasestudo.length - 1]["link_apresentacao"][
              "flag_material_padrao"
            ],
        });
        setSequenciaVersoes(data.sequencia_versoes);
        setTemasEstudo(data.temasestudo);
        setSelectedPraticaId(data.temasestudo.length - 1);
        setTextoUsuarioDescobertaguiada("");
        setTextoUsuarioHandson("");
        setLoading(false);
        setOpen(false);
      })
      .catch((error) => {
        console.error("Erro na solicitação:", error);
        alert("Erro na solicitação:", error);
        setLoading(false);
        setOpen(false);
      });
  };

  const addModulo = (modulo, sigla, objetivo, novotema, metaaprendizagem) => {
    setLoading(true);
    setOpen(true);
    const novoModulo = "Novo! - " + modulo;
    setModulos([novoModulo, ...modulos]); // Adiciona novo módulo ao dropdown list
    setSelectedModulo(novoModulo);
    fetch(`${apiUrl}/api/addmodulo`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        modulo: modulo,
        email: infouser.email,
        novotema: novotema,
        siglamodulo: sigla,
        objetivo: objetivo,
        metaaprendizagem: metaaprendizagem,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setSlide(presentationTemplate + data.id_link_temaestudo.toString());
        setApresentacaoPersonalizada({
          nome_tema: data.temasestudo[data.temasestudo.length - 1]["nome"],
          data_criacao:
            data.temasestudo[data.temasestudo.length - 1]["link_apresentacao"][
              "data_criacao"
            ],
          flag_material_padrao:
            data.temasestudo[data.temasestudo.length - 1]["link_apresentacao"][
              "flag_material_padrao"
            ],
        });
        setDisplayTextDescricao(objetivo);
        setLinkCompilado({
          link: "",
          data_criacao: "",
          personalizado: false,
          link_padrao: "",
        });
        setDisplayComecepeloporque(
          "https://docs.google.com/presentation/d/" +
            data.id_link_comecepeloporque
        );
        setSequenciaVersoes(data.sequencia_versoes);
        setTemasEstudo(data.temasestudo);
        setSelectedPraticaId(data.temasestudo.length - 1);
        setInfoNovoModulo({
          modulo: modulo,
          novotema: novotema,
          siglamodulo: sigla,
          objetivo: objetivo,
          metaaprendizagem: metaaprendizagem,
        });
        // Reseta valores dos blocos de descobertaguiada e handson
        setTextoUsuarioDescobertaguiada("");
        setTextoUsuarioHandson("");
        setLoading(false);
        setOpen(false);
      })
      .catch((error) => {
        console.error("Erro na solicitação:", error);
        alert("Erro na solicitação:", error);
        setLoading(false);
        setOpen(false);
      });
  };

  // Função para encontrar o subarray a que o índice pertence, e se ele está em um array de uma posição apenas
  const findSubarrayFromIndex = (array2D, index) => {
    let flattenedIndex = 0;
    let arrayinterno = [];

    for (let subarray of array2D) {
      if (flattenedIndex + subarray.length > index) {
        arrayinterno = subarray; // Retorna o subarray correspondente
        break;
      }
      flattenedIndex += subarray.length;
    }
    return arrayinterno && arrayinterno.length === 1; // Verifica se o subarray tem exatamente um item
  };

  const getFacilitadores = useCallback(async () => {
    const response = await fetch(`${apiUrl}/api/getfacilitador?ativo`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Ocorreu um erro ao buscar os facilitadores");
    }

    const data = await response.json();
    setFacilitadores(data.map((facilitador) => facilitador.email));
  }, []);

  const handleFacilitadorSelect = (selectedOption) => {
    setSelectedFacilitador(selectedOption);
  };

  useEffect(() => {
    if (infouser.userlevel === "administrador") {
      getFacilitadores()
    }
  }, [infouser.userlevel, getFacilitadores]);

  useEffect(() => {
    if (!selectedFacilitador || !selectedModulo) {
      return;
    }
    if (selectedFacilitador) {
      handleModuloSelect(selectedModulo);
      setLoading(true);
      setOpen(true);
      console.log('Facilitador selecionado:', selectedFacilitador);
    }
  }, [selectedFacilitador]);

  return (
    <div id="facilitadores-container">
      <Topo
        dropdownoptions={modulos}
        dropdownselectedmodulo={selectedModulo}
        dropdownonselect={handleModuloSelect}
        infouser={infouser}
        setMostrarModalladoAladoB={setMostrarModalladoAladoB}
        dropdownfacilitadoroptions={facilitadores}
        dropdownfacilitadorselected={selectedFacilitador}
        dropdownfacilitadoronselect={handleFacilitadorSelect}
      />
      <div style={divStyle}>
        {selectedModulo && firstModuloSelected && (
          <SegundoTopo
            objetivo={displayTextDescricao}
            linkcompilado={linkCompilado}
            selectedModulo={selectedModulo}
            linkcomecepeloporque={displayComecepeloporque}
            setLinkcomecepeloporque={setDisplayComecepeloporque}
            onclickcallbackCompile={handleButtonCompilar}
            onclickcallbackSaving={handleButtonSalvar}
            infouser={infouser}
            setLoading={setLoading}
            setOpen={setOpen}
          />
        )
        }
        {selectedModulo &&
          firstModuloSelected &&
          !selectedModulo.includes("Pad -") &&
          !selectedModulo.includes("Novo! -") &&
          (!selectedModulo ? (
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                padding: "0px",
              }}
            >
              Módulo não possui material padrão.
            </div>
          ) : (
            <Button
              onClick={() => setMostrarModalMaterialPadrao(true)}
              disabled={!selectedModulo}
              style={{
                paddingLeft: "0.2%",
                backgroundColor: "transparent",
                border: "none",
                color: "blue",
                textDecoration: "underline",
                display: "flex",
                width: "fit-content",
              }}
            >
              Atualizar para material padrão
            </Button>
          ))}
        {selectedModulo && firstModuloSelected && (
          <BlocoSequenciaTema
            temasestudo={temasestudo}
            sequencia_versoes={sequencia_versoes}
            temaadicionado={temaadicionado}
            linkcompilado={linkCompilado}
            onModificarListacallback={modificarLista}
            onModificarListaVersoescallback={ModificarListaVersoes}
            onSelectBlococallback={selectTemaEstudo}
            disabledcondition={!selectedModulo}
            selectedModulo={selectedModulo}
            objetivo={displayTextDescricao}
            linkcomecepeloporque={displayComecepeloporque}
            setLinkcomecepeloporque={setDisplayComecepeloporque}
            infouser={infouser}
            setLoading={setLoading}
            setOpen={setOpen}
            setInfouser={setInfouser}
            selectedFacilitador={selectedFacilitador}
            disabledconditionRemovetema={
              !selectedModulo ||
              !temasestudo ||
              temasestudo.length <= 1 ||
              (findSubarrayFromIndex(sequencia_versoes, selectedPraticaId) &&
                infouser.userlevel !== "administrador")
            }
            disabledconditionRemoveaula={
              !selectedModulo ||
              !sequencia_versoes ||
              (sequencia_versoes.length <= 1 &&
                sequencia_versoes[0] &&
                sequencia_versoes[0].length <= 1)
            }
            addTemaEstudo={addTemaEstudo}
            removeTemaEstudo={removeTemaEstudo}
            addAula={addAula}
            removeAula={removeAula}
            addModulo={addModulo}
            valorInput={valorInput}
            setValorInput={setValorInput}
            disableDirectionCondition={
              findSubarrayFromIndex(sequencia_versoes, selectedPraticaId) &&
              infouser.userlevel !== "administrador"
            }
          />
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h2>{apresentacaoPersonalizada.nome_tema}</h2>
        </div>
        <Row>
          <Slide
            temasestudo={temasestudo}
            src={slide}
            openNewTab={EditTemaEstudo}
            apresentacaoPersonalizada={apresentacaoPersonalizada}
            dropdownoptions={modulos}
            dropdownselectedmodulo={selectedModulo}
            dropdownonselect={handleModuloSelect}
            selectedPraticaId={selectedPraticaId}
          />
          {selectedModulo && firstModuloSelected && (
            <BlocoExercicios
              disabledcondition={!selectedModulo}
              temasestudo={temasestudo}
              temaadicionado={temaadicionado}
              selectedBloco={selectedPraticaId}
              descobertaguiada_ref={blocoSequenciaPraticasRef_descobertaguiada}
              descobertaguiada_text={textoUsuarioDescobertaguiada}
              descobertaguiada_onChangecallback={
                handleTextoUsuarioChangeDescobertaguiada
              }
              descobertaguiada_onKeyDowncallback={handleKeyDownDescobertaguiada}
              descobertaguiada_onClickcallback={
                handleConfirmClickDescobertaguiada
              }
              descobertaguiada_onClickRemovecallback={
                handleRemoveClickDescobertaguiada
              }
              handson_ref={blocoSequenciaPraticasRef_handson}
              handson_text={textoUsuarioHandson}
              handson_onChangecallback={handleTextoUsuarioChangeHandson}
              handson_onKeyDowncallback={handleKeyDownHandsOn}
              handson_onClickcallback={handleConfirmClickHandson}
              handson_onClickRemovecallback={handleRemoveClickHandson}
            />
          )}
        </Row>
        <DialogText
          openflag={open}
          dialogtitle="Por favor, aguarde"
          dialogcontexttextflag={loading}
        />
        <DialogText
          openflag={openLoading}
          dialogtitle="Carregando"
          dialogcontexttextflag={loading}
        />
        <ModalDesfazer
          desfazerAcao={desfazerRemoveTemaEstudo}
          show={mostrarModal}
          onCloseModalCallback={onCloseModalCallback}
        />
        <ModalDesfazer
          desfazerAcao={desfazerRemoveAula}
          show={mostrarModalRemoveAula}
          onCloseModalCallback={onCloseModalCallbackRemoveAula}
        />
        <ModalAviso
          texto={textoModalSalvar}
          show={mostrarModalSalvar}
          setTextoModalSalvar={setTextoModalSalvar}
          setMostrarModalSalvar={setMostrarModalSalvar}
        />
        <ModalCompilacao
          texto={textoModalCompilacao}
          show={mostrarModalCompilacao}
          onCloseModalCallback={logOut}
          setTextoModalCompilacao={setTextoModalCompilacao}
          setMostrarModalCompilacao={setMostrarModalCompilacao}
        />
        <ModalGeracaoLadoALadoB
          show={mostrarModalladoAladoB}
          setMostrarModalladoAladoB={setMostrarModalladoAladoB}
          generateLadoALadoB={generateLadoALadoB}
        />
        <ModalPuxaMaterialPadrao
          show={mostrarModalMaterialPadrao}
          setMostrarModalMaterialPadrao={setMostrarModalMaterialPadrao}
          generateMaterialPadrao={() => handleGetPadrao(selectedModulo)}
        />
      </div>
    </div>
  );
}

export default AreaFacilitador;
