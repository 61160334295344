import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import './SegundoTopo.css';
import Button from 'react-bootstrap/Button'; // Importação do componente de botão do React Bootstrap
import { getAuthData } from '../../auth';
import { jwtDecode } from 'jwt-decode';

const apiUrl = process.env.REACT_APP_API_HOST;
// const apiUrl = process.env.REACT_APP_API_HOST_LOCAL;

function SegundoTopo({
  objetivo,
  linkcompilado,
  selectedModulo,
  linkcomecepeloporque,
  setLinkcomecepeloporque,
  onclickcallbackCompile,
  onclickcallbackSaving,
  infouser,
  setLoading,
  setOpen
  }) {

  const [nomeModulo, setNomeModulo] = useState('');

  useEffect(() => {
    if (selectedModulo.toLowerCase().includes('pad -')) {
      // Remove 'Pad - ' e pega o restante da string
      setNomeModulo(selectedModulo.substring(6)); // 'Pad - ' tem 6 caracteres
    }
    else {
      setNomeModulo(selectedModulo);
    }
  }, [objetivo]); // Assim, o nome do módulo só muda quando o objetivo do módulo mudar

  const handleClick = () => {
    setLoading(true);
    setOpen(true);
    if(linkcomecepeloporque === "") {
      fetch(`${apiUrl}/api/novocomecepeloporque`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "modulo": selectedModulo, "email": infouser.email }),
      })
      .then((response) => response.json())
      .then((data) => {
        setLinkcomecepeloporque(data.linkcomecepeloporque);
        // Redireciona o usuário para uma nova página da web
        window.open(data.linkcomecepeloporque, '_blank');
      })
      .catch((error) => {console.error('Erro na solicitação:', error);
        alert('Erro na solicitação:', error);
      })
      .finally(() => {
        setLoading(false);
        setOpen(false);
      });
    }
    else {
      // Redireciona o usuário para uma nova página da web
      window.open(linkcomecepeloporque, '_blank');
      setLoading(false);
      setOpen(false);
    }
  };

  return (
    <Row id='total' style={{backgroundColor:"#F9F9F9", border: "solid 1px rgb(198,198,198)"}}>
      <Col style={{ margin: '1%'}} md={7} className='left'>
        {!!selectedModulo && 
          (<Row style={{ display: 'flex', flexDirection: 'column' }}>
              <Row id="descricao">{nomeModulo}: {objetivo} </Row>
                {linkcompilado && typeof linkcompilado === 'object' && (
                  <Row style={{ display: 'block'}} id="link_material_compilado">
                    {
                      <>
                        Link {linkcompilado.personalizado && <i style={{padding: '0px'}}>personalizado</i>} :
                            <a 
                              href={linkcompilado.link === "Não possui material compilado prévio" ? "#" : linkcompilado.link} 
                              target="_blank" 
                              rel="noopener noreferrer" 
                              style={{
                                  pointerEvents: linkcompilado.link === "Não possui material compilado prévio" ? "none" : "auto",
                                  color: linkcompilado.link === "Não possui material compilado prévio" ? "gray" : "blue",
                                  textDecoration: linkcompilado.link === "Não possui material compilado prévio" ? "none" : "underline"
                                }}
                              onClick={(e) => {
                                if (linkcompilado.link === "Não possui material compilado prévio") e.preventDefault();
                              }}
                            >
                            {linkcompilado.link === "Não possui material compilado prévio" ? linkcompilado.link : "Apresentação compilada"}</a>
                            {/* {linkcompilado.link === "Não possui material compilado prévio" ? '' : (linkcompilado.personalizado ? <i> - Personalizado</i> : (linkcompilado.link.length > 0 ? ' - Padrão' : ''))} */}
                            <br/>
                      </>
                    }
                    Link padrão: <a href={linkcompilado.link_padrao} 
                             target="_blank" 
                            //  rel="noopener noreferrer" 
                            //  onClick={(e) => { e.preventDefault();
                            //   }}
                              style={{
                                pointerEvents: linkcompilado.link_padrao ? "auto" : "none",
                                color: linkcompilado.link_padrao ? "blue":"gray",
                                textDecoration: linkcompilado.link_padrao ? "underline":"none",
                                cursor: linkcompilado.link_padrao ? "pointer":"none"
                              }} rel="noreferrer"
                              >
                              {linkcompilado.link_padrao ? "Apresentação padrão compilada" : "Não possui material padrão compilado prévio"}</a>
                  </Row>
                )}
                {linkcompilado && typeof linkcompilado === 'object' && !!selectedModulo &&(
                  <Row id="data_material_compilado">Data de criação: {linkcompilado.data_criacao}</Row>
                )}
                {infouser.userlevel === 'administrador' && !!selectedModulo && (
                  linkcomecepeloporque === "" ?
                  <div style={{display: 'flex', alignItems: 'flex-start', padding: '0px'}}>
                      Módulo não possui link de comece pelo porquê. Clique para
                      <span
                          onClick={handleClick}
                          style={{
                              color: 'blue',
                              textDecoration: 'underline',
                              cursor: 'pointer',
                              marginLeft: '4px'
                          }}
                          disabled={!selectedModulo}
                      >
                          criá-lo
                      </span>
                  </div>
                  :
                  <Button
                    variant="dark"
                    onClick={handleClick}
                    disabled={!selectedModulo}
                    style={{paddingLeft: '0.2%',
                            backgroundColor: 'transparent', 
                            border: 'none', 
                            color: 'blue', 
                            textDecoration: 'underline', 
                            display: 'flex',
                            width: 'fit-content' }}>
                    Comece pelo porquê
                  </Button>
                )}
          </Row>
        )}
      </Col>
      <Col md={4} id='right'>
        <Row className='bt-right'>
          <Col className='save-bt'>
            {!!selectedModulo && (
              <Button style={{width: '130px', 
                              borderRadius: '10px', 
                              fontSize: 'small', 
                              height: '28px', 
                              display: 'flex', 
                              justifyContent: 'center', 
                              alignItems: 'center'}}  
                      variant="dark" 
                      onClick={onclickcallbackSaving} 
                      disabled={!selectedModulo}>
                <strong>Salvar</strong>
              </Button>
            )}
          </Col>
          <Col className='comp-bt'>
            {!!selectedModulo && (
              <Button variant="dark" 
                onClick={onclickcallbackCompile} 
                disabled={(!selectedModulo) || (linkcomecepeloporque === "")} 
                style={{backgroundColor: '#ff5003', 
                        color: '#fff', 
                        border: 'none', 
                        width: '130px', 
                        borderRadius: '10px', 
                        fontSize: 'small', 
                        height: '28px', 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center'}}>
              <strong> Compilar </strong>
              </Button>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SegundoTopo;
