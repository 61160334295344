// BlocoSequenciaTema.js
import React, { useEffect, useState } from 'react';
import BlocoTema from './BlocoTema';
import BlocoFixo from './BlocoFixo';
import './BlocoSequenciaTema.css'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import '../AreaFacilitador/AreaFacilitador.css';
import Button from 'react-bootstrap/Button'; // Importação do componente de botão do React Bootstrap
import { Dialog, DialogContent, DialogActions, TextField } from '@mui/material';
import { Button as MUIButton } from '@mui/material';
import { getAuthData } from '../../auth';
import { jwtDecode } from 'jwt-decode';
import { Row, Col } from 'react-bootstrap'
import { SelectAllSharp } from '@mui/icons-material';


function DialogAction({id, 
  buttonlabel, 
  onclickcallback, 
  disabledcondition,
  opendialog,
  onclosecallback,
  onkeydowncallback,
  dialogcontent,
  muibuttonaction,
  muibuttontext, 
  valorInput}) {
return (
  <div id={id}>
    <Button style={{backgroundColor: 'transparent', border: 'none', color: 'grey', textDecoration: 'underline'}} variant="dark" onClick={onclickcallback} disabled={disabledcondition}>
      {buttonlabel}
    </Button>
    <Dialog open={opendialog} onClose={onclosecallback} onKeyDown={onkeydowncallback} maxWidth='lg'>
      <DialogContent>
        {dialogcontent}
      </DialogContent>
      <DialogActions>
        <MUIButton onClick={onclosecallback}>Cancelar</MUIButton>
        <MUIButton onClick={muibuttonaction} disabled={valorInput.length<=0}>{muibuttontext}</MUIButton>
      </DialogActions>
    </Dialog>
  </div>
);
}

function toPascalCase(str) {
// Remove espaços extras e divide a string em palavras
const words = str.trim().split(/\s+/);

// Converte a primeira letra de cada palavra para maiúscula
const pascalWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());

// Junta as palavras com espaços adicionais
return pascalWords.join(' ');
}

function phraseToPascalCase(str) {
// Remove espaços extras e divide a string em palavras
const words = str.trim().split(/\s*\.\s*/);

// Converte a primeira letra de cada palavra para maiúscula
const pascalWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());

// Junta as palavras com espaços adicionais e pontos finais
return pascalWords.join('. ').concat('.');
}

function DialogActionForm({id, 
   buttonlabel, 
   onclickcallback, 
   disabledcondition,
   opendialog,
   onclosecallback,
   dialogcontent,
   muibuttonaction,
   muibuttontext}) {
    return (
      <div id={id}>
        <Button style={{backgroundColor: 'transparent', border: 'none', color: 'grey', textDecoration: 'underline'}} variant="dark" onClick={onclickcallback} disabled={disabledcondition}>
          {buttonlabel}
        </Button>
        <Dialog open={opendialog} 
                onClose={onclosecallback} 
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault(); // Evita a submissão padrão do formulário
                    const formData = new FormData(event.currentTarget.children[2].children[0]);
                    const formJson = Object.fromEntries(formData.entries());

                    // Verifica se todos os campos do formulário estão preenchidos
                    if (formJson.modulo && formJson.sigla && formJson.objetivo && formJson.novotema) {
                      const modulo = toPascalCase(formJson.modulo);
                      const sigla = formJson.sigla.trim().toUpperCase();
                      const objetivo = phraseToPascalCase(formJson.objetivo);
                      const metaaprendizagem = parseInt(formJson.metaaprendizagem, 10);
                      const novotema = formJson.novotema.trim().toUpperCase();
                      // Verifica se metaaprendizagem é um número inteiro
                      if (Number.isInteger(metaaprendizagem) && (metaaprendizagem>=0) && (metaaprendizagem<=5)) {
                        muibuttonaction(modulo, sigla, objetivo, metaaprendizagem, novotema);
                        onclosecallback();
                      } else {
                        alert('O valor de metaaprendizagem deve ser um número inteiro.');
                      }
                    } else {
                      alert('Por favor, preencha todos os campos do formulário.');
                    }
                  }
                }}
                maxWidth='lg'
                PaperProps={{
                  component: 'form',
                  onSubmit: (event) => {
                    event.preventDefault();
                    const formData = new FormData(event.currentTarget);
                    const formJson = Object.fromEntries(formData.entries());
                    const modulo = toPascalCase(formJson.modulo);
                    const sigla = formJson.sigla.trim().toUpperCase();
                    const objetivo = phraseToPascalCase(formJson.objetivo);
                    const metaaprendizagem = parseInt(formJson.metaaprendizagem, 10);
                    const novotema = formJson.novotema.trim().toUpperCase();
                    // Verifica se metaaprendizagem é um número inteiro
                    if (Number.isInteger(metaaprendizagem) && (metaaprendizagem>=0) && (metaaprendizagem<=5)) {
                      muibuttonaction(modulo, sigla, objetivo, metaaprendizagem, novotema);
                      onclosecallback();
                    } else {
                      alert('O valor de metaaprendizagem deve ser um número inteiro.');
                    }
                  },
                }}>
          <DialogContent>
            {dialogcontent}
          </DialogContent>
          <DialogActions>
            <MUIButton onClick={onclosecallback}>Cancelar</MUIButton>
            <MUIButton type="submit">{muibuttontext}</MUIButton>
          </DialogActions>
        </Dialog>
      </div>
    );
}

const BlocoSequenciaTema = ({temasestudo, 
                             sequencia_versoes, 
                             temaadicionado=false, 
                             onModificarListacallback, 
                             onModificarListaVersoescallback, 
                             onSelectBlococallback,
                             disabledcondition,
                             disabledconditionRemovetema,
                             disabledconditionRemoveaula,
                             addTemaEstudo,
                             removeTemaEstudo,
                             selectedModulo,
                             objetivo,
                             linkcomecepeloporque,
                             setLinkcomecepeloporque,
                             infouser,
                             setLoading,
                             setOpen,
                             setInfouser,
                             addAula,
                             removeAula,
                             addModulo,
                             valorInput,
                             setValorInput,
                             linkCompilado,
                             selectedFacilitador,
                             disableDirectionCondition
                            }) => {
  const [blocoTema, setBlocoTema] = useState(
    // temasestudo
    [
      { id: 1, nome: 'Tema 1', codigo: 0, isSelected: true },
      { id: 2, nome: 'Tema 2', codigo: 1, isSelected: false },
      // Adicione mais sub-blocos conforme necessário
    ]
  );
  const [indexSeparador, setIndexSeparador] = useState({'aula': 0, 'index': 0});
  const [renderCounter, setRenderCounter] = useState(0);
  const [userlevel, setUserlevel] = useState('usuario');
  const [openInputTema, setOpenInputTema] = useState(false);
  const [openInputAula, setOpenInputAula] = useState(false);
  const [openInputModulo, setOpenInputModulo] = useState(false);
  const [sequenciaTemaHeight, setSequenciaTemaHeight] = useState('13.5em');

  useEffect(() => {
    setRenderCounter(renderCounter+1);
    const authData = getAuthData();
    var email = ''
    var level = ''
    if (authData){
      email = jwtDecode(authData.token).sub.user;
      level = jwtDecode(authData.token).sub.level;
    
      setUserlevel(level);
    }
  }, []);
  // Hook que processa sempre que a props temasestudo for modificada
  useEffect(() => {
    // Tranformando o array de strings em um array de objetos com chaves id e texto
    const arrayDeObjetos = temasestudo.map((item, index) => ({
      id: index + 1, // Adicionando 1 para garantir que o ID seja único
      nome: item.nome,
      codigo: item.codigo,
      descobertaguiada: item.descobertaguiada,
      handson: item.handson,
      numero_aula: item.numero_aula,
      sequencia_tema: item.sequencia_tema,
      isSelected: item.isSelected,
      link_apresentacao: item.link_apresentacao,
    }));
    setBlocoTema(arrayDeObjetos);
    
    const resultado = [];
    let num_aula = 0;
    let index_separador = 0;
    // primeira aula
    resultado.push({'aula': num_aula, 'index': index_separador})
    // Itera sobre o sequencia_versoes e atualiza os indices que devem possuir o separador
    for(let aula=0; aula<sequencia_versoes.length-1; aula++) {
      num_aula = aula;
      index_separador = index_separador + sequencia_versoes[aula].length;
      resultado.push({'aula': num_aula+1, 'index': index_separador})
    } 
    setIndexSeparador(resultado);
    // Remove palavras curtas antes da análise da quantidade de espaços
    const filterShortWords = (text) => {
      const shortWords = ['E', 'O', 'A'];
      const regex = new RegExp(`\\b(${shortWords.join('|')})\\b`, 'gi');
      return text.replace(regex, '').replace(/\s+/g, ' ').trim();
    };
    // Calcula quantidade de espaços em um texto para controlar a altura do componente SequenciaTemasEstudo
    const calculateMaxSpaces = () => {
      const maxSpacesCount = temasestudo.reduce((max, item) => {
        const filteredName = filterShortWords(item.nome);
        const spaceCount = (filteredName.match(/ /g) || []).length;
        return Math.max(max, spaceCount);
      }, 0);
      // Calcula quantidade máxima de caracteres em um texto para controlar a altura do componente SequenciaTemasEstudo
      const maxCharCount = temasestudo.reduce((max, item) => {
        const charCount = item.nome.length;
        return Math.max(max, charCount);
      }, 0);
      if((maxSpacesCount >= 6) || (maxCharCount>=35)) {
        setSequenciaTemaHeight('19.5em');
      }
      else if((maxSpacesCount >= 4) || (maxCharCount>30)) {
        setSequenciaTemaHeight('16.3em');
      } else {
        setSequenciaTemaHeight('14.5em');
      }

    };

    calculateMaxSpaces();
  }, [temasestudo, temaadicionado, sequencia_versoes]);

  const handleBlocoSelect  = (id, temaSelecionado) => {
    const novosBlocos = blocoTema.map((bloco) => ({
      ...bloco,
      isSelected: bloco.id === id ? true : false,
    }));
    if (!temaSelecionado) {
      setBlocoTema(novosBlocos);
      onSelectBlococallback(novosBlocos);
    }
  };

  let contadorBootcamp = 0;

  // Função para renderizar o BlocoFixo
  const renderBlocoFixo = (iFixo, objetivo, linkCompilado, linkcomecepeloporque, setLinkcomecepeloporque, infouser, setLoading, setOpen, setInfouser, selectedModulo) => (
    <BlocoFixo
      objetivo={objetivo}
      iFixo={iFixo}
      texto={iFixo === 0 ? 'Comece pelo porquê' : 'Lado A Lado B'}
      link={linkCompilado}
      linkcomecepeloporque={linkcomecepeloporque}
      setLinkcomecepeloporque={setLinkcomecepeloporque}
      infouser={infouser}
      setLoading={setLoading}
      setOpen={setOpen}
      setInfouser={setInfouser}
      selectedModulo={selectedModulo}
      selectedFacilitador={selectedFacilitador}
      
      // moveSubBloco={handleMoveSubBloco}
      onSelect={() => handleBlocoSelect(false)}
      isSelected={false}
      qtdetemas={temasestudo.length+2}
    />
  );

  // Função para renderizar o BlocoTema
  const renderBlocoTema = (tema, index) => (
    <BlocoTema
      aula={contadorBootcamp}
      key={index}
      index={index}
      id={tema.id}
      texto={tema.nome}
      
      // moveSubBloco={handleMoveSubBloco}
      onSelect={() => handleBlocoSelect(tema.id, tema.isSelected)}
      isSelected={tema.isSelected}
      qtdetemas={temasestudo.length}
    />
  );

  // Função para renderizar o BlocoEscuro
  const renderBlocoEscuro = (index) => (
    <div key={`aula-${index}`} style={{ position: 'relative', width: '3%', height: '125%', bottom: '25%' }}>
        <div style={{ position: "absolute", top: '12%', paddingLeft: '5em', transform: "translate(-50%, -50%)" }}>
          <p key={`p-${index}`} style={{ color: "black", width: 'max-content' }}>Aula {index+1}</p>
        </div>
        <div key={`barra-${index}`} style={{ backgroundColor: "black", width: "0.2em", height: "100%", position: "absolute", left: "0%", top: "0%", marginLeft: "15%" }}></div>
    </div>
  );

  // Função principal para renderizar alternadamente BlocoTema e BlocoEscuro
  const renderBlocos = (blocoTema) => {
    const resultado = [];
    let index_sep = 2;

    resultado.push(renderBlocoFixo(1, objetivo, linkCompilado, linkcomecepeloporque, setLinkcomecepeloporque, infouser, setLoading, setOpen, setInfouser, selectedModulo));  // Bloco fixo 1
    resultado.push(renderBlocoFixo(0, objetivo, linkCompilado, linkcomecepeloporque, setLinkcomecepeloporque, infouser, setLoading, setOpen, setInfouser, selectedModulo));  // Bloco fixo 2

    blocoTema.forEach((tema, index) => {
      // Renderiza BlocoTema
      resultado.push(renderBlocoTema(tema, index));
    });

    if (Array.isArray(indexSeparador))
    {
      indexSeparador.forEach((separador, index) => {
        // Renderiza BlocoTema
        resultado.splice(separador.index+index_sep, 0-2, renderBlocoEscuro(index));
        index_sep++;
      });
    } else {
      resultado.splice(indexSeparador.index, +2, renderBlocoEscuro(indexSeparador.index));
    }
    return resultado;
  };

  const MoverBlocoDireita = () => {
    // Encontrar o índice do primeiro objeto com isSelected true
    const indexDoSelecionado = temasestudo.findIndex(objeto => objeto.isSelected);

    const dragSubBloco = blocoTema[indexDoSelecionado];
    const updatedSubBlocos = [...blocoTema];
    
    let updatedSeqVersoes = [...sequencia_versoes];

    // Encontrar o array interno do sequencia_versoes relacionado ao indexDoSelecionado
    let num_aula;
    let total_temas = 0;

    // ID do tema selecionado, para atualizar praticas selecionadas (descobertaguiada e handson)
    let selectedBlocoid = indexDoSelecionado;

    for (let i = 0; i < updatedSeqVersoes.length; i++) {
      total_temas = total_temas + updatedSeqVersoes[i].length;
      // Verificar qual array interno (aula) o tema movido faz parte
      if(indexDoSelecionado < total_temas) {
        num_aula = i;
        break; // Parar a busca assim que encontrar o índice desejado
      }
    }

    let totalanteriores = total_temas - updatedSeqVersoes[num_aula].length
    let indiceArrayInterno = indexDoSelecionado - totalanteriores

    // Verificar se o array interno não é o último array
    if(num_aula !== updatedSeqVersoes.length - 1) {
      // Verificar se não é o último elemento do array
      if(indiceArrayInterno !== updatedSeqVersoes[num_aula].length - 1) {
        updatedSubBlocos.splice(indexDoSelecionado, 1); // Remove o elemento sendo arrastado do array atualizado
        updatedSubBlocos.splice(indexDoSelecionado+1, 0, dragSubBloco); // Insere o elemento sendo arrastado no novo índice de destino (hoverIndex)
        // 2. Atualizar o campo sequencia_tema de todos os elementos que tenham numero_aula igual a 2
        updatedSubBlocos[indexDoSelecionado].sequencia_tema = updatedSubBlocos[indexDoSelecionado].sequencia_tema-1;
        updatedSubBlocos[indexDoSelecionado+1].sequencia_tema = updatedSubBlocos[indexDoSelecionado+1].sequencia_tema+1;
      }
      else { // -> Se for o ultimo elemento do array, a posição do tema não muda, apenas a posição dele na sequencia de versoes
        // Devemos mudar também o número da aula
        // 1. Atualizar o campo numero_aula do elemento no indexSelecionado
        const novoNumeroAula = updatedSubBlocos[indexDoSelecionado].numero_aula+1;
        // 2. Incrementar o campo sequencia_tema de todos os elementos que tenham numero_aula igual a 2
        updatedSubBlocos.forEach((item) => {
          if (item.numero_aula === novoNumeroAula) {
              item.sequencia_tema += 1;
          }
        });
        updatedSubBlocos[indexDoSelecionado].numero_aula = novoNumeroAula; // Substitua novoNumeroAula pelo valor desejado
        updatedSubBlocos[indexDoSelecionado].sequencia_tema = 1; // Substitua novoNumeroAula pelo valor desejado
      }
      
    }
    else { // Se for o último array, verificar se não é o último tema
      if(indexDoSelecionado !== total_temas-1) {
        updatedSubBlocos.splice(indexDoSelecionado, 1); // Remove o elemento sendo arrastado do array atualizado
        updatedSubBlocos.splice(indexDoSelecionado+1, 0, dragSubBloco); // Insere o elemento sendo arrastado no novo índice de destino (hoverIndex)
        // 2. Atualizar o campo sequencia_tema de todos os elementos que tenham numero_aula igual a 2
        updatedSubBlocos[indexDoSelecionado].sequencia_tema = updatedSubBlocos[indexDoSelecionado].sequencia_tema-1;
        updatedSubBlocos[indexDoSelecionado+1].sequencia_tema = updatedSubBlocos[indexDoSelecionado+1].sequencia_tema+1;
      }
      else {
        alert("Não é possível mover este bloco para direita!");
      }
    }   

    // Verificar se não é o último elemento do array interno atual
    if (indiceArrayInterno < updatedSeqVersoes[num_aula].length - 1) {
      // Mover para o próximo índice dentro do mesmo array interno
      const temp = updatedSeqVersoes[num_aula][indiceArrayInterno];
      updatedSeqVersoes[num_aula][indiceArrayInterno] = updatedSeqVersoes[num_aula][indiceArrayInterno + 1];
      updatedSeqVersoes[num_aula][indiceArrayInterno + 1] = temp;
      selectedBlocoid = indexDoSelecionado+1;
    } else { // Se é o ultimo elemento do array interno
      // Verificar se o array interno não é o último array
      if(num_aula !== updatedSeqVersoes.length - 1) {
        // Mover para o próximo array interno
        const temp = updatedSeqVersoes[num_aula][indiceArrayInterno];
        updatedSeqVersoes[num_aula].splice(indiceArrayInterno, 1); // Remover o elemento do array interno atual
        updatedSeqVersoes[num_aula + 1].unshift(temp); // Adicionar ao início do próximo array interno
        if (indexSeparador[num_aula+1].index === indexDoSelecionado+1){
          selectedBlocoid = indexDoSelecionado;
        }
        else {
          selectedBlocoid = indexDoSelecionado+1;
        }
      }
      else {
        // Se for o último elemento do último array. não pode mover para a direita
        selectedBlocoid = indexDoSelecionado;
      }
    }

    setBlocoTema(updatedSubBlocos);
    // Tranformando o array de objetos em um array contendo apenas os valores da chave 'texto'
    onModificarListacallback(updatedSubBlocos);
    
    onModificarListaVersoescallback(updatedSeqVersoes, selectedBlocoid);
  }

  
  const handleCancel = (openinputfunc) => {
    setValorInput('');
    openinputfunc(false);
  };

  const handleSubmit = (addfunc, openinputfunc) => {
    // Limpe o input e feche o dialog
    setValorInput('');
    openinputfunc(false);
    addfunc();
  };

  const handleKeyDown = (event, addfunc, openinputfunc) => {
    if (!openInputModulo && event.key === 'Enter' && !event.shiftKey) {
      handleSubmit(addfunc, openinputfunc);
    }
  };

  const handleSubmitModulo = (modulo, sigla, objetivo, metaaprendizagem, novotema) => {
    // Feche o dialog
    setOpenInputModulo(false);
    addModulo(modulo, sigla, objetivo, novotema, metaaprendizagem);
  };

  const MoverBlocoEsquerda = () => {
    // Encontrar o índice do primeiro objeto com isSelected true
    const indexDoSelecionado = temasestudo.findIndex(objeto => objeto.isSelected);

    const dragSubBloco = blocoTema[indexDoSelecionado];
    const updatedSubBlocos = [...blocoTema];
  
    let updatedSeqVersoes = [...sequencia_versoes];

    // Encontrar o array interno do sequencia_versoes relacionado ao indexDoSelecionado
    let num_aula;
    let total_temas = 0;

    // ID do tema selecionado, para atualizar praticas selecionadas (descobertaguiada e handson)
    let selectedBlocoid = indexDoSelecionado;

    for (let i = 0; i < updatedSeqVersoes.length; i++) {
      total_temas = total_temas + updatedSeqVersoes[i].length;
      // Verificar qual array interno (aula) o tema movido faz parte
      if(indexDoSelecionado < total_temas) {
        num_aula = i;
        break; // Parar a busca assim que encontrar o índice desejado
      }
    }

    let totalanteriores = total_temas - updatedSeqVersoes[num_aula].length
    let indiceArrayInterno = indexDoSelecionado - totalanteriores

    // Verificar se o array interno não é o primeiro array
    if(num_aula !== 0) {
      // Verificar se não é o primeiro elemento do array
      if(indiceArrayInterno !== 0) {
        updatedSubBlocos.splice(indexDoSelecionado, 1); // Remove o elemento sendo arrastado do array atualizado
        updatedSubBlocos.splice(indexDoSelecionado-1, 0, dragSubBloco); // Insere o elemento sendo arrastado no novo índice de destino (hoverIndex)
        // 2. Atualizar o campo sequencia_tema de todos os elementos que tenham numero_aula igual a 2
        updatedSubBlocos[indexDoSelecionado].sequencia_tema = updatedSubBlocos[indexDoSelecionado].sequencia_tema+1;
        updatedSubBlocos[indexDoSelecionado-1].sequencia_tema = updatedSubBlocos[indexDoSelecionado-1].sequencia_tema-1;
      }
      else { // -> Se for o primeiro elemento do array, a posição do tema não muda, apenas a posição dele na sequencia de versoes
        // Devemos mudar também o número da aula
        // 1. Atualizar o campo numero_aula do elemento no indexSelecionado
        const novoNumeroAula = updatedSubBlocos[indexDoSelecionado].numero_aula-1;
        // 2. Incrementar o campo sequencia_tema de todos os elementos que tenham numero_aula igual a 2
        updatedSubBlocos.forEach((item) => {
          if (item.numero_aula === novoNumeroAula) {
              item.sequencia_tema -= 1;
          }
        });
        updatedSubBlocos[indexDoSelecionado].numero_aula = novoNumeroAula; // Substitua novoNumeroAula pelo valor desejado
        updatedSubBlocos[indexDoSelecionado].sequencia_tema = updatedSeqVersoes[0].length+1;; // Substitua novoNumeroAula pelo valor desejado
      }
    }
    else { // Se for o primeiro array, verificar se não é o primeiro tema
      if(indexDoSelecionado !== 0) {
        updatedSubBlocos.splice(indexDoSelecionado, 1); // Remove o elemento sendo arrastado do array atualizado
        updatedSubBlocos.splice(indexDoSelecionado-1, 0, dragSubBloco); // Insere o elemento sendo arrastado no novo índice de destino (hoverIndex)
        // 2. Atualizar o campo sequencia_tema de todos os elementos que tenham numero_aula igual a 2
        updatedSubBlocos[indexDoSelecionado].sequencia_tema = updatedSubBlocos[indexDoSelecionado].sequencia_tema+1;
        updatedSubBlocos[indexDoSelecionado-1].sequencia_tema = updatedSubBlocos[indexDoSelecionado-1].sequencia_tema-1;
      }
      else {
        alert("Não é possível mover este bloco para esquerda!");
      }
    }

    // Verificar se não é o primeiro elemento do array interno atual
    if (indiceArrayInterno > 0) {
      // Mover para o índice anterior dentro do mesmo array interno
      const temp = updatedSeqVersoes[num_aula][indiceArrayInterno];
      updatedSeqVersoes[num_aula][indiceArrayInterno] = updatedSeqVersoes[num_aula][indiceArrayInterno - 1];
      updatedSeqVersoes[num_aula][indiceArrayInterno - 1] = temp;
      selectedBlocoid = indexDoSelecionado-1;
    } else { // Se é o primeiro elemento do array interno
      // Verificar se o array interno não é o primeiro array
      if(num_aula !== 0) {
        // Mover para o array interno anterior
        const temp = updatedSeqVersoes[num_aula][indiceArrayInterno];
        updatedSeqVersoes[num_aula].splice(indiceArrayInterno, 1); // Remover o elemento do array interno atual
        updatedSeqVersoes[num_aula - 1].push(temp); // Adicionar ao início do próximo array interno
        if (indexSeparador[num_aula].index === indexDoSelecionado){
          selectedBlocoid = indexDoSelecionado;
        }
        else {
          selectedBlocoid = indexDoSelecionado-1;
        }
      }
      else {
        // Se for o último elemento do último array. não pode mover para a direita
        selectedBlocoid = indexDoSelecionado;
      }
    }

    setBlocoTema(updatedSubBlocos);
    // Tranformando o array de objetos em um array contendo apenas os valores da chave 'texto'
    onModificarListacallback(updatedSubBlocos);
    
    onModificarListaVersoescallback(updatedSeqVersoes, selectedBlocoid);
  }

  return (
    <div id='SequenciaTemasEstudo' style={{width: '100%', height: sequenciaTemaHeight, display: 'flex', alignItems: 'flex-end'}}>
      {temasestudo.length>0 &&
        <div id='BlocoSequenciaTema' style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}>
          <div style={{width: '100%', overflowX: 'auto', overflowY: 'clip', paddingTop: '4%', paddingBottom: '0.1%', display: 'flex',  fontSize: 'small',  overflowWrap: 'break-word'}}>
            {/* Renderiza alternadamente BlocoTema e BlocoEscuro */}
            {renderBlocos(blocoTema)}
          </div>
          <Row id="botoes_adicionar" style={{ height: '30%' }}>
            <Col>
              { !disabledcondition && ( 
                <DialogAction id= "add_tema" 
                            buttonlabel= "Adicionar tema" 
                            classes={{ button: "manipular-button" }}
                            onclickcallback= {() => setOpenInputTema(true)}
                            disabledcondition={disabledcondition} 
                            opendialog={openInputTema} 
                            onclosecallback={() => handleCancel(setOpenInputTema)}
                            onkeydowncallback={(e) => handleKeyDown(e, addTemaEstudo, setOpenInputTema)}
                            dialogcontent={<TextField
                              label="Insira o nome do novo tema de estudo"
                              variant="outlined"
                              autoFocus
                              fullWidth
                              defaultValue={valorInput}
                              onChange={(e) => setValorInput(e.target.value.toUpperCase().trim())}
                            
                            />}
                            muibuttonaction={() => handleSubmit(addTemaEstudo, setOpenInputTema)} 
                            muibuttontext="Enviar"
                            valorInput={valorInput}
                            />)}
            </Col>
            <Col>
              { !disabledcondition &&(
                  <Button style={{ border: 'none', backgroundColor: 'transparent', color: 'gray', textDecoration: 'underline' }} variant="dark" id= "remove_tema" onClick={removeTemaEstudo} disabled={disabledconditionRemovetema}>
                  Remover tema
                </Button>
              )}
            </Col>
            <Col></Col>
            <Col md={6} sm={6}>
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1%'}}>
                  <Button 
                    onClick={MoverBlocoEsquerda}
                    disabled={disableDirectionCondition}
                    style={{ backgroundColor: 'transparent', width: '70px', height: '40px', border: '1px grey solid', borderRadius: '2px', padding: '0px'}}>
                    <ArrowLeftIcon
                      style={{ color: 'black', border: 'none', width: '70px', height: '40px' }}
                    />
                  </Button>
                  <Button 
                    onClick={MoverBlocoDireita}
                    disabled={disableDirectionCondition}
                    style={{ backgroundColor: 'transparent', width: '70px', height: '40px', border: '1px grey solid', borderRadius: '2px', padding: '0px'}}>
                    <ArrowRightIcon
                      style={{ color: 'black', border: 'none', width: '70px', height: '40px' }}
                    />
                  </Button>
              </div>
            </Col>
            <Col>
              {userlevel === 'administrador' && !disabledcondition && (
                  <DialogAction id= "add_aula" 
                                buttonlabel= "Adicionar aula" 
                                classes={{ button: "manipular-button" }} 
                                style={{}}
                                onclickcallback= {() => setOpenInputAula(true)}
                                disabledcondition={disabledcondition} 
                                opendialog={openInputAula} 
                                onclosecallback={() => handleCancel(setOpenInputAula)}
                                onkeydowncallback={(e) => handleKeyDown(e, addAula, setOpenInputAula)}
                                dialogcontent={<TextField
                                  label="Insira o nome do novo tema de estudo da nova aula"
                                  variant="outlined"
                                  autoFocus
                                  fullWidth
                                  defaultValue={valorInput}
                                  onChange={(e) => setValorInput(e.target.value.toUpperCase().trim())}
                                />}
                                muibuttonaction={() => handleSubmit(addAula, setOpenInputAula)} 
                                muibuttontext="Enviar"
                                valorInput={valorInput}
                                />
                )}
            </Col>
            <Col>
              { userlevel === 'administrador' && !disabledcondition && (
              <Button style={{ border: 'none', backgroundColor: 'transparent', color: 'gray', textDecoration: 'underline' }}  variant="dark" id= "remove_aula" onClick={removeAula} disabled={disabledconditionRemoveaula}>
                Remover aula
              </Button>
              )}
            </Col>
            <Col>
              {userlevel === 'administrador'&& !disabledcondition &&  (
                <DialogActionForm id= "add_modulo" 
                              buttonlabel= "Criar módulo" 
                              classes={{ button: "manipular-button" }}
                              onclickcallback= {() => setOpenInputModulo(true)}
                              opendialog={openInputModulo} 
                              onclosecallback={() => handleCancel(setOpenInputModulo)}
                              dialogcontent={<>
                              <TextField
                                label="Insira o nome do novo módulo"
                                variant="outlined"
                                autoFocus
                                required
                                margin="dense"
                                fullWidth
                                name="modulo"
                              />
                              <TextField
                                label="Insira a sigla do novo módulo"
                                variant="outlined"
                                required
                                margin="dense"
                                fullWidth
                                name="sigla"
                              />
                              <TextField
                                label="Insira o objetivo do novo módulo"
                                variant="outlined"
                                required
                                margin="dense"
                                fullWidth
                                name="objetivo"
                              />
                              <TextField
                                label="Insira o valor da meta de aprendizagem do novo módulo (0 a 5)"
                                variant="outlined"
                                required
                                margin="dense"
                                fullWidth
                                name="metaaprendizagem"
                              />
                              <TextField
                                label="Insira o nome do novo tema de estudo do novo módulo"
                                variant="outlined"
                                required
                                margin="dense"
                                fullWidth
                                name="novotema"
                              />
                              </>}
                              muibuttonaction={handleSubmitModulo} 
                              muibuttontext="Enviar" />
              )}
            </Col>
          </Row>
        </div>
      }
    </div>
  );
};

export default React.memo(BlocoSequenciaTema);
