import React, { useState, useEffect } from "react"; // Importa o módulo React e os hooks useState e useEffect
import "./AreaAdministrador.css"; // Importa o arquivo CSS associado ao componente
// import Button from '@mui/material/Button';
import HistoricoAlteracoes from "../HistoricoAlteracoes/HistoricoAlteracoes"; // Importa o componente HistoricoAlteracoes de outro diretório
import { List, ListItem, ListItemText } from "@mui/material"; // Importa os componentes List, ListItem e ListItemText do Material-UI
import DialogText from "../DialogText";
import Topo from "../AreaFacilitador/Topo";
import { Row, Col } from "react-bootstrap";
import HistoricoAlteracoesRecentes from "../HistoricoAlteracoesRecentes/HistoricoAlteracoesRecentes";
import ComparaModificacoes from "../ComparaModificacoes/ComparaModificacoes";

const apiUrl = process.env.REACT_APP_API_HOST;
// const apiUrl = "http://127.0.0.1:5000";

function AreaAdministrador() {
  // Declaração do componente funcional AreaAdministrador
  const [historicoAtualizacoes, setHistoricoAtualizacoes] = useState({}); // Declara o estado historicoAtualizacoes e a função setHistoricoAtualizacoes para atualizá-lo
  const [itemSelecionado, setItemSelecionado] = useState({'index':0, 'status':'pendente'}); // Declara o estado itemSelecionado e a função setItemSelecionado para atualizá-lo
  const [historicoEmAguardo, setHistoricoEmAguardo] = useState({}); // Inicializa historicoEmAguardo como um array vazio
  const [nomeTemaEstudo, setNomeTemaEstudo] = useState({});
  // const [historicoSelecionado, setHistoricoSelecionado] = useState("pendente"); // Inicializa historicoEmAguardo como um array vazio
  const [historicoAprovacoesRecentes, setHistoricoAprovacoesRecentes] =
    useState([]); // Inicializa historicoAprovacoesRecentes como um array vazio
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  // Declara o estado historicoEmAguardo e a função setHistoricoEmAguardo para atualizá-lo
  const endpoints = {
    // Declara um objeto contendo os endpoints da API
    historicoAtualizacoes: `${apiUrl}/api/historicoatualizacoes`,
    historicoEmAguardo: `${apiUrl}/api/historicoatualizacoesemaguardo`,
    historicoRecentes: `${apiUrl}/api/historicoatualizacoesrecentes`,
    efetuarAtualizacao: `${apiUrl}/api/efetuaratualizacao`,
    nomeTemaEstudo: `${apiUrl}/api/nometemaestudo`,
  };

  useEffect(() => {
    setLoading(true);
    setOpen(true);
    loadData();
  }, []);

  const loadData = async (atualizarpendentes=true, atualizaremaguardo=true, atualizarrecentes=true) => {
    // setLoading(true);
    // setOpen(true);
    try {
      let data1, data2, data3;

      if (atualizarpendentes) {
        data1 = await fetchData(endpoints.historicoAtualizacoes);
        setHistoricoAtualizacoes(data1);
      }

      if (atualizaremaguardo) {
        data2 = await fetchData(endpoints.historicoEmAguardo);
        setHistoricoEmAguardo(data2);
      }

      if (atualizarrecentes) {
        data3 = await fetchData(endpoints.historicoRecentes);
        setHistoricoAprovacoesRecentes(data3);
      }

      /*if (atualizarnomeestudo) {
        try {
          data4 = await fetchData(endpoints.nomeTemaEstudo); // Verifique a estrutura dos dados
          setNomeTemaEstudo(data4.nometema);
        } 
        finally {
          setLoading(false); // Após carregar os dados, atualize o loading
          console.log('dados: ', nomeTemaEstudo)
        }
      }*/
      // const [response1, response2, response3] = await Promise.all([
      //   fetchData(endpoints.historicoAtualizacoes),
      //   fetchData(endpoints.historicoEmAguardo),
      //   fetchData(endpoints.historicoRecentes),
      // ]);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  useEffect(() => {
    const loadNomeTemaEstudo = async () => {
      try {
        const data4 = await fetchData(endpoints.nomeTemaEstudo);
        if (data4 && data4.nometema) {
          setNomeTemaEstudo(data4);
        } else {
          console.log("Estrutura de dados inesperada.");
        }
      } catch (error) {
        console.error("Erro ao carregar dados do nomeTemaEstudo", error);
      } finally {
        setLoading(false);
      }
    };

    loadNomeTemaEstudo();
  }, []);


  const fetchData = async (url) => {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || "Erro na solicitação");
    }
    return data;
  };

  const handleButtonClick = async (status) => {
    setLoading(true);
    setOpen(true);
    // Segundo atualiza as variáveis do back-end
    try {
      const response = await fetch(endpoints.efetuarAtualizacao, {
        // Realiza uma requisição para o endpoint efetuarAtualizacao
        method: "POST", // Utiliza o método POST
        headers: {
          "Content-Type": "application/json", // Define o cabeçalho Content-Type como application/json
        },
        body:
         JSON.stringify({
                id_log: (itemSelecionado.status === "pendente") 
                        ? historicoAtualizacoes[itemSelecionado.index].id_log
                        : historicoEmAguardo[itemSelecionado.index].id_log,
                status_aprovacao: status,
              }) // Envia os dados da atualização no corpo da requisição
      });

      if (!response.ok) {
        // Verifica se a resposta não foi bem-sucedida
        throw new Error("Failed to fetch"); // Lança um erro indicando que a requisição falhou
      }

      const data = await response.json(); // Converte a resposta para JSON
      if (itemSelecionado.status === "pendente") {
        if((status==="em_aguardo")) {
          loadData(true, true, false);
        }
        else {
          loadData(true, false, true);
        }
      }
      else if (itemSelecionado.status === "em_aguardo") {
        loadData(false, true, true);
      }
      
      // Restante do código para lidar com a resposta da requisição...
    } catch (error) {
      // Captura qualquer erro ocorrido durante o processo
      console.error("Erro ao processar ação:", error); // Exibe o erro no console
      // Exibir uma mensagem de erro para o usuário informando que houve um problema ao processar a solicitação
    }
  };  

  return (
    <div id="container">
      <div id="section-login">
        <Topo flagadmin={true} />
      </div>
      <div className="restante">
        <div id="pendentes-e-historico">
          <h1 style={{ fontWeight: "bold", fontSize: "xx-large" }}>
            Modificações Pendentes
          </h1>
          <div id="alteracoes">
            <HistoricoAlteracoes
              historicoAtualizacoes={historicoAtualizacoes}
              setItemSelecionado={setItemSelecionado}
              atualizarHistorico={setHistoricoAtualizacoes}
              historicoEmAguardo={historicoEmAguardo}
              // selecionarItemHistoricoEmAguardo={
              //   selecionarItemHistoricoEmAguardo
              // }
              // callback={setHistoricoSelecionado}
            />
          </div>
          <HistoricoAlteracoesRecentes historicoAprovacoesRecentes={historicoAprovacoesRecentes}/>
        </div>
        
        <ComparaModificacoes
          historicoAtualizacoes={historicoAtualizacoes}
          historicoEmAguardo={historicoEmAguardo}
          itemSelecionado={itemSelecionado}
          nomeTemaEstudo={nomeTemaEstudo}
          // historicoSelecionado={historicoSelecionado}
          handleButtonClick={handleButtonClick} />
        <DialogText
          openflag={open}
          dialogtitle="Por favor, aguarde"
          dialogcontexttextflag={loading}
        />
      </div>
    </div>
  );
}

export default AreaAdministrador;
