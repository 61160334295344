import "./AreaFacilitador.css";
import React from "react";
import DropdownList from "../DropdownList/DropdownList";
import logoHarve from "../assets/logoHarve.svg";
import { Row, Col } from "react-bootstrap";
import { clearAuthData } from "../../auth";
import MenuIcon from "@mui/icons-material/Menu";
import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom"; // Importe useNavigate
// import Divider from '@mui/material/Divider';

const apiUrl = process.env.REACT_APP_API_HOST;
// const apiUrl = "http://127.0.0.1:5000";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      fontSize: "16px", // Aqui você pode ajustar o tamanho das fontes
      "& .MuiSvgIcon-root": {
        fontSize: 24, // Tamanho das fontes dos ícones
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

function Topo({
  dropdownoptions,
  dropdownselectedmodulo,
  dropdownonselect,
  flagadmin = false,
  infouser,
  setMostrarModalladoAladoB,
  dropdownfacilitadoroptions,
  dropdownfacilitadorselected,
  dropdownfacilitadoronselect
}) {
  // A props falgadmin vai indicar se a página atual é do administrador
  // Possui valor false por padrão. Quando for true, não renderiza o dropdown de seleção de módulo
  const [anchorElMenu1, setAnchorElMenu1] = React.useState(null);
  const [anchorElMenu2, setAnchorElMenu2] = React.useState(null);
  const openMenu1 = Boolean(anchorElMenu1);
  const openMenu2 = Boolean(anchorElMenu2);
  const navigate = useNavigate(); // Obtenha a função de navegação

  const handleClickMenu1 = (event) => {
    setAnchorElMenu1(event.currentTarget);
  };

  const handleClickMenu2 = (event) => {
    setAnchorElMenu2(event.currentTarget);
  };

  const handleCloseMenu1 = () => {
    setAnchorElMenu1(null);
  };

  const handleAreaFacilitador = () => {
    setAnchorElMenu1(null);
    navigate("/AreaFacilitador");
  };

  const handleAreaAdministrador = () => {
    setAnchorElMenu1(null);
    navigate("/AreaAdministrador");
  };

  const handleAreaMentorModulo = () => {
    setAnchorElMenu1(null);
    navigate("/MentorModulo");
  };

  const handleEditLadoALadoB = () => {
    setAnchorElMenu1(null);
    // Abre a nova aba com a mensagem de "Carregando, por favor, aguarde"
    if (infouser?.link_ladoA_ladoB) {
      const newTab = window.open("", "_blank");
      newTab.opener = null; // Impede que a nova aba tenha acesso ao navegador pai
      newTab.location.href = infouser.link_ladoA_ladoB;
    } else {
      setMostrarModalladoAladoB(true);
    }
  };

  const handleOpenLinkReferencia = () => {
    setAnchorElMenu1(null);
    // Abre a nova aba com a mensagem de "Carregando, por favor, aguarde"
    const newTab = window.open("", "_blank");
    newTab.opener = null; // Impede que a nova aba tenha acesso ao navegador pai
    newTab.location.href =
      "https://docs.google.com/presentation/d/18-oWzMzWUs4CmffiThJj-L9LHHOJZ-xBeReQm3BRuRo/edit#slide=id.g1e17a2c1bb8_0_0";
  };

  const handleLogout = () => {
    clearAuthData(); // Chama a função para fazer logout
    setAnchorElMenu1(null);
    navigate("/");
  };

  return (
    <Row id="topo">
      <Col md={1} id="col-logo">
        <img id="logo-app-img" src={logoHarve} alt="Logo da harve" />
      </Col>
      <Col md={8} id="modulo">
        {!flagadmin && infouser?.userlevel === "administrador" && (
          <DropdownList
            placeholder="Selecione um facilitador"
            options={dropdownfacilitadoroptions}
            selectedOption={dropdownfacilitadorselected}
            onSelect={dropdownfacilitadoronselect}
          />
        )}
        {!flagadmin && (
          <DropdownList
            placeholder="Selecione um módulo "
            options={dropdownoptions}
            selectedOption={dropdownselectedmodulo}
            onSelect={dropdownonselect}
          />
        )}
      </Col>
      {/* Coluna para ajudar no espaçamento */}
      <Col />
      <Col>
        <Row>
          <div className="menu-icon-container">
            <MenuIcon
              id="demo-customized-button-menu1"
              aria-controls={openMenu1 ? "demo-customized-menu1" : undefined}
              aria-haspopup="true"
              aria-expanded={openMenu1 ? "true" : undefined}
              disableElevation
              onClick={handleClickMenu1}
              style={{ fontSize: "35px", marginLeft: "10%", cursor: "pointer" }}
              className="menu-icon"
            />
            <StyledMenu
              id="demo-customized-menu1"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button-menu1",
              }}
              anchorEl={anchorElMenu1}
              open={openMenu1}
              onClose={handleCloseMenu1}
            >
              {flagadmin ? (
                <MenuItem
                  onClick={
                    flagadmin ? handleAreaFacilitador : handleAreaAdministrador
                  }
                  disableRipple
                  // Aqui você pode ajustar o tamanho das fontes
                >
                  {" "}
                  Área do Facilitador
                </MenuItem>
              ) : (
                infouser?.userlevel === "administrador" && (
                  <MenuItem
                    onClick={
                      flagadmin
                        ? handleAreaFacilitador
                        : handleAreaAdministrador
                    }
                    disableRipple
                    // Aqui você pode ajustar o tamanho das fontes
                  >
                    Área do Admin
                  </MenuItem>
                )
              )}
              {infouser?.userlevel === "administrador" && (
                <MenuItem
                  onClick={handleAreaMentorModulo}
                  disableRipple
                  // Aqui você pode ajustar o tamanho das fontes
                >
                  {" "}
                  Área Mentor-Módulo
                </MenuItem>
              )}
              <MenuItem
                onClick={handleOpenLinkReferencia}
                disableRipple
                // Aqui você pode ajustar o tamanho das fontes
              >
                {/* <WorkIcon /> */}
                Template de apresentação
              </MenuItem>

              {/* <Divider sx={{ my: 1.0 }} /> */}
              {!flagadmin && (
                <MenuItem
                  onClick={handleEditLadoALadoB}
                  disableRipple
                  // Aqui você pode ajustar o tamanho das fontes
                >
                  {/* <WorkIcon /> */}
                  Editar ladoA ladoB
                </MenuItem>
              )}
              <MenuItem
                onClick={handleLogout}
                disableRipple
                // Aqui você pode ajustar o tamanho das fontes
              >
                {/* <WorkIcon /> */}
                Logout
              </MenuItem>
            </StyledMenu>
          </div>
        </Row>
      </Col>
    </Row>
  );
}

export default Topo;
