import "./AreaFacilitador.css";
import ilustracaoModulo from "../assets/ilustracaoModulo.svg";
import DropdownList from "../DropdownList/DropdownList";
import { Row, Col } from "react-bootstrap";
import React from "react";
import { OpenInNew } from "@mui/icons-material";

function Slide({
  temasestudo,
  src,
  openNewTab,
  apresentacaoPersonalizada,
  dropdownoptions,
  dropdownselectedmodulo,
  dropdownonselect,
  selectedPraticaId,
}) {
  return (
    <Col lg={3} style={{ flex: 1 }}>
      {temasestudo.length ? (
        <div style={{ height: "100%" }}>
          <h3 style={{ textAlign: "center" }}>Tema de estudo:</h3>
          <a
            href={src}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "black" }}
          >
            <Col
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                border: "1px solid black",
                height: "100%",
              }}
            >
              <OpenInNew style={{ fontSize: "6rem", color: "#C7C7C7" }} />
              <span>Editar tema de estudo</span>
            </Col>
          </a>
        </div>
      ) : (
        <Row style={{ marginTop: "5%" }} className="div-total-image">
          <Col md={4} className="image-container">
            <Row>
              <img
                style={{ width: "200px" }}
                id="img-sem-micro"
                src={ilustracaoModulo}
                alt="Imagem"
              />
            </Row>
            <Row>
              <p>Para começar, selecione um módulo</p>
            </Row>
            <Row>
              <DropdownList
                placeholder="Selecione um módulo "
                options={dropdownoptions}
                selectedOption={dropdownselectedmodulo}
                onSelect={dropdownonselect}
              />
            </Row>
          </Col>
        </Row>
      )}
    </Col>
  );
}

export default Slide;
