import "./AreaFacilitador.css";
import { useState } from "react";
import BlocoPraticas from "./BlocoPraticas";
import TemporaryToast from "./TemporaryToast";
import { Col } from "react-bootstrap";

function BlocoExercicios({
  temasestudo,
  temaadicionado,
  selectedBloco,
  descobertaguiada_ref,
  descobertaguiada_text,
  descobertaguiada_onChangecallback,
  descobertaguiada_onKeyDowncallback,
  descobertaguiada_onClickcallback,
  descobertaguiada_onClickRemovecallback,
  handson_ref,
  handson_text,
  handson_onChangecallback,
  handson_onKeyDowncallback,
  handson_onClickcallback,
  disabledcondition,
  handson_onClickRemovecallback,
}) {
  const [opendescobertaguiada, setOpendescobertaguiada] = useState(true);
  const [openhandson, setOpenhandson] = useState(true);
  const [ativaToastDescobertaguiada, setAtivaToastDescobertaguiada] =
    useState(false);
  const [ativaToastHandson, setAtivaToastHandson] = useState(false);

  function handleDescobertaguiada_onClickcallback() {
    setAtivaToastDescobertaguiada(true);
    descobertaguiada_onClickcallback();
  }

  function handleHandson_onClickcallback() {
    setAtivaToastHandson(true);
    handson_onClickcallback();
  }

  return (
    <Col lg={9} id="slides_exercicios">
      {!disabledcondition && (
        <Col
          id="colDescobertaguiada"
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "flex-start",
            padding: "0",
            height: "100%",
          }}
        >
          <div
            className="arrowCollapse"
            onClick={() => {
              setOpendescobertaguiada(!opendescobertaguiada);
            }}
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <h3> Descoberta Guiada: </h3>
          </div>
          {/* <Collapse in={!opendescobertaguiada}> */}
          <div style={{ width: "100%", height: "100%" }}>
            <BlocoPraticas
              ref={descobertaguiada_ref}
              temasestudo={temasestudo}
              descobertaguiada={true}
              temaadicionado={temaadicionado}
              selectedBloco={selectedBloco}
              textareavalue={descobertaguiada_text}
              onChangecallback={descobertaguiada_onChangecallback}
              onKeyDowncallback={descobertaguiada_onKeyDowncallback}
              onClickcallback={handleDescobertaguiada_onClickcallback}
              onClickRemovecallback={descobertaguiada_onClickRemovecallback}
            />
          </div>
          {/* </Collapse> */}
        </Col>
      )}
      {!disabledcondition && (
        <Col
          id="colHandson"
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "flex-start",
            padding: "0",
            height: "100%",
          }}
        >
          <div
            className="arrowCollapse"
            onClick={() => {
              setOpenhandson(!openhandson);
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <h3> Hands on:</h3>
          </div>

          <div style={{ width: "100%", height: "100%" }}>
            <BlocoPraticas
              ref={handson_ref}
              temasestudo={temasestudo}
              descobertaguiada={false}
              temaadicionado={temaadicionado}
              selectedBloco={selectedBloco}
              textareavalue={handson_text}
              onChangecallback={handson_onChangecallback}
              onKeyDowncallback={handson_onKeyDowncallback}
              onClickcallback={handleHandson_onClickcallback}
              onClickRemovecallback={handson_onClickRemovecallback}
            />
          </div>
        </Col>
      )}
      {ativaToastDescobertaguiada && (
        <TemporaryToast
          message="Descoberta Guiada salva com sucesso!"
          duration={1750}
          callback={setAtivaToastDescobertaguiada}
        />
      )}
      {ativaToastHandson && (
        <TemporaryToast
          message="Hands On salvo com sucesso!"
          duration={1750}
          callback={setAtivaToastHandson}
        />
      )}
    </Col>
  );
}

export default BlocoExercicios;
