// BlocoFixo.js
import React, { useState, useEffect } from 'react';
import { getAuthData } from '../../auth';
import { jwtDecode } from 'jwt-decode';
import ModalGeracaoLadoALadoB from "../Modal/ModalGeracaoLadoALadoB";

const BlocoFixo = ({ texto,
                     iFixo,
                     onSelect, 
                     isSelected,
                     linkCompilado,
                     selectedModulo,
                     linkcomecepeloporque,
                     setLinkcomecepeloporque,
                     infouser,
                     setLoading,
                     setOpen,
                     objetivo,
                     selectedFacilitador,
                     setInfouser}) => {
  const [posicaoMouse, setPosicaoMouse] = useState({ x: 0, y: 0 });
  const [mouseSolto, setMouseSolto] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [selectedBloco, setSelectedBloco] = useState();
  const [larguraDaDiv, setLarguraDaDiv] = useState(0);

  // Função para calcular a largura da div
  const calcularLarguraDaDiv = () => {
    const larguraDaTela = window.innerWidth;
    const larguraDesejada = larguraDaTela / 7;
    setLarguraDaDiv(larguraDesejada);
  };

  // Calcula a largura da div quando o componente é montado e quando a janela é redimensionada
  useEffect(() => {
    calcularLarguraDaDiv();
    window.addEventListener('resize', calcularLarguraDaDiv);
    return () => {
      window.removeEventListener('resize', calcularLarguraDaDiv);
    };
  }, []);

  const handleMouseMove = (e) => {
    setPosicaoMouse({ x: e.clientX, y: e.clientY });
  };

  const handleMouseDown = () => {
    setMouseSolto(false);
    setIsClicked(true);
    setSelectedBloco(iFixo);
  };

  const [nomeModulo, setNomeModulo] = useState('');

  const [modulos, setModulos] = useState([]);

  useEffect(() => {
    if (selectedModulo.toLowerCase().includes('pad -')) {
      // Remove 'Pad - ' e pega o restante da string
      setNomeModulo(selectedModulo.substring(6)); // 'Pad - ' tem 6 caracteres
    }
    else {
      setNomeModulo(selectedModulo);
    }
  }, [objetivo]); // Assim, o nome do módulo só muda quando o objetivo do módulo mudar

  const apiUrl = process.env.REACT_APP_API_HOST;
  // const apiUrl = process.env.REACT_APP_API_HOST_LOCAL;

  const handleClick = () => {
    setLoading(true);
    setOpen(true);
    if (iFixo === 0) {
      if (linkcomecepeloporque === "") {
        fetch(`${apiUrl}/api/novocomecepeloporque`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ "modulo": selectedModulo, "email": infouser.email }),
        })
          .then((response) => response.json())
          .then((data) => {
            setLinkcomecepeloporque(data.linkcomecepeloporque);
            // Redireciona o usuário para uma nova página da web
            window.open(data.linkcomecepeloporque, '_blank');
          })
          .catch((error) => {
            console.error('Erro na solicitação:', error);
            alert('Erro na solicitação:', error);
          })
          .finally(() => {
            setLoading(false);
            setOpen(false);
          });
      } else {
        // Redireciona o usuário para a URL já salva
        window.open(linkcomecepeloporque, '_blank');
        setLoading(false);
        setOpen(false);
      }
    }
    if (iFixo === 1){
      const checkAndGenerateLadoALadoB = async () => {
        setLoading(true);
        setOpen(true);
    
        const authData = getAuthData();
        if (authData){
          const email = jwtDecode(authData.token).sub.user;
          const level = jwtDecode(authData.token).sub.level;
          console.log(email)
          setInfouser({
            ...infouser,
            email: email,
            userlevel: level,
          });
    
          try {
            const modulosResponse = await fetch(`${apiUrl}/api/mentormodulo?email=${selectedFacilitador?selectedFacilitador:email}&level=${selectedFacilitador?"usuario":level}`, {
              method: "GET",
              headers: { "Content-Type": "application/json" },
            });
    
            const modulosData = await modulosResponse.json();
            setModulos(modulosData);
    
            // Verificar se o link 'ladoA_ladoB' já existe para esse usuário
            const ladoAladoBResponse = await fetch(`${apiUrl}/api/ladoAladoB?email=${selectedFacilitador?selectedFacilitador:email}`, {
              method: "GET",
              headers: { "Content-Type": "application/json" },
            });
    
            const ladoAladoBData = await ladoAladoBResponse.json();
    
            // Se o usuário já tem o arquivo, usamos o link
            if (ladoAladoBData) {
              // Se o usuário já tem o arquivo, abrir o link diretamente em uma nova aba
              const newTab = window.open("", "_blank");
              newTab.opener = null; // Impede que a nova aba tenha acesso ao navegador pai
              newTab.location.href = ladoAladoBData;
              
              // Atualiza o estado do infouser com o link
              setInfouser({
                ...infouser,
                email: selectedFacilitador?selectedFacilitador:email,
                userlevel: level,
                link_ladoA_ladoB: ladoAladoBData,
              });
            } else {
              // Caso não tenha o arquivo, geramos o link
              const generateResponse = await fetch(`${apiUrl}/api/ladoAladoB?email=${selectedFacilitador?selectedFacilitador:email}&novo=true`, {
                method: "GET",
                headers: { "Content-Type": "application/json" },
              });
    
              const generateData = await generateResponse.json();
              const newTab = window.open("", "_blank");
              newTab.opener = null; // Impede que a nova aba tenha acesso ao navegador pai
              newTab.location.href = generateData;
    
              setInfouser({
                ...infouser,
                email: selectedFacilitador?selectedFacilitador:email,
                userlevel: level,
                link_ladoA_ladoB: generateData,
              });
            }
          } catch (error) {
            console.error("Erro na solicitação:", error);
            alert("Erro na solicitação:", error);
          } finally {
            setLoading(false);
            setOpen(false);
          }
        } else {
          setLoading(false);
          setOpen(false);
        }
      };
      checkAndGenerateLadoALadoB();
    }
  };

  const handleBlocoClick = () => {
    handleClick();
  };


  const style = {
    border: '1px solid #ccc',
    paddingRight: '3px',
    paddingLeft: '3px',
    backgroundColor: 'white',
    opacity: 1,
    backgroundColor: isSelected ? '#87ef96' : 'white',
    fontWeight: isSelected ? 'bold' : 'normal',
    width: `${larguraDaDiv}px`,
    height: '100%',
    cursor: 'pointer',
    fontSize: '1em' 
  };
  return (
    <div id={`bloco-${iFixo}`} style={style} onMouseMove={handleMouseMove} onMouseDown = {handleMouseDown} onClick={handleBlocoClick}>
      <div style={{ left: 'auto', position: 'relative', transition: 'left 0.2s', padding: '1em' }}>
        {texto}
      </div>
    </div>
  );
};


export default BlocoFixo;
